import React, { Component } from "react";
import { ThemeContext } from "../ThemeContext";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  hideRightSidebar,
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
} from "../store/actions";
// users
//import user4 from '../assets/images/mm_tran.png';

class ProfileMenu extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      layoutType: this.props.layoutType,
      checked: true,
      size: "default",
      disabled: false
    };

    this.toggle = this.toggle.bind(this);
    this.changeLayout = this.changeLayout.bind(this);
  }

  handleChange = e => {
    this.setState({ checked: e.target.checked });
  };

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  changeLayout(e) {
    if (e.target.checked) {
      this.props.changeLayout(e.target.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        layoutType: this.props.layoutType,
      });
    }
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {(value) => (
          <React.Fragment>
            <Dropdown
              isOpen={this.state.menu}
              toggle={this.toggle}
              className="d-inline-block custom-dropdown"
            >
              <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
              >
                {/* <img className="rounded-circle header-profile-user" src={user4} alt="Header Avatar" /> */}
                <i class="mdi mdi-settings-outline profile_font"></i>
              </DropdownToggle>
              <DropdownMenu right>
                {/* <DropdownItem tag="a" href="#"><i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>My Profile</DropdownItem> */}
                <FormGroup className="dropdown-item">
                  <Label for="layout">Layouts</Label>
                  <div>
                    <CustomInput
                      type="radio"
                      id="verticalLayout"
                      name="layout"
                      label="Vertical"
                      value="vertical"
                      checked={this.state.layoutType === "vertical"}
                      onChange={this.changeLayout}
                    />
                    <CustomInput
                      type="radio"
                      id="horizontalLayout"
                      name="layout"
                      label="Horizontal"
                      value="horizontal"
                      checked={this.state.layoutType === "horizontal"}
                      onChange={this.changeLayout}
                    />
                  </div>
                </FormGroup>
                <div className="dropdown-divider"></div>
                {/* <Link to="/logout" className="dropdown-item">
                  <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                  <span>Logout</span>
                </Link> */}
                <div className="dropdown-item">
                <Label for="layout">Theme</Label>
                <ToggleSwitch checked={this.state.checked}
                  text={value.theme}
                  disabled={this.state.disabled}
                  onChange={(e) => { this.handleChange(e); value.toggleThemeHandler() }}
                  offstyle="btn-warning"
                  onstyle="btn-success" />
                </div>

                <div className="dropdown-divider"></div>
                <Link to="/logout" className="dropdown-item">
                  <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                  <span>Logout</span>
                </Link>
              </DropdownMenu>
            </Dropdown>
          </React.Fragment>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const mapStatetoProps = state => {
  return { ...state.Layout };
};
export default connect(mapStatetoProps, {
  hideRightSidebar,
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme
})(withRouter(ProfileMenu));
