import React from "react";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
//import ToolkitProvider, { CSVExport, ColumnToggle  } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import queryString from "query-string";
import { MetaTags } from "react-meta-tags";
import moment from "moment";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
import axios from "axios";
import {
  Card,
  CardBody,
  Modal,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import ReactExport from "react-data-export-fix1";
//import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import "chartist/dist/scss/chartist.scss";
import { Bar } from "react-chartjs-2";

// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

//import DarkUnica from "highcharts/themes/dark-unica";
import bellCurve from "highcharts/modules/histogram-bellcurve"; //module
//DarkUnica(Highcharts);

bellCurve(Highcharts); //init module

const { ExportCSVButton } = CSVExport;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const selectRow = {
//   mode: "checkbox",
//   clickToSelect: true,
// };

class ParametriDetails extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      modal_standard: false,
      loading: true,
      products: [],
      locations: [],
      bar_ranges: [],
      bar_values: [],
      gaussion_data: [],
      logged_user: "",
      export_btn: false,
      id_description_graph: "",
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.tog_standard = this.tog_standard.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.getVersionLocation = this.getVersionLocation.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  tog_standard(loc) {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    const data_source = localStorage.getItem("data_source");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    });
    //console.log(user_id);
    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org"
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });

    const values = queryString.parse(this.props.location.search);
    const brand = values.brand;
    const family = values.family;
    const chassis = values.chassis;
    const chassis_year_from = values.chassis_year_from;
    const chassis_year_to = values.chassis_year_to;
    const engine_code = values.engine_code;
    const plant_id = values.plant_id;
    const ecu = values.ecu;
    const typeidk = values.typeidk;

    var lang = localStorage.getItem("lang");
    if (lang === "it") {
      var lang_id = "1";
    } else {
      var lang_id = "5";
    }

    if (data_source === "internal") {
      var data_source_final = "test_get_parametri_details/";
    } else {
      var data_source_final = "get_parametri_details/";
    }

    fetch(
      config.API +
        // "get_parametri_details/",
        data_source_final,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
          brand: brand,
          family: family,
          chassis: chassis,
          chassis_year_from: chassis_year_from,
          chassis_year_to: chassis_year_to,
          engine_code: engine_code,
          plant_id: plant_id,
          ecu: ecu,
          lang_id: lang_id,
          typeidk: typeidk,
          company: "Nexion",
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          products: findresponse.parametri,
          loading: false,
        });
      });
  }

  //   getVersionLocation(id) {
  //     var loc_id = id.target.value;
  //     //console.log(loc_id);
  //     fetch(config.API + "get_parametri_riferimento/" + loc_id, {
  //       headers: {
  //         Nexion_API_Key: config.API_KEY,
  //       },
  //     })
  //       .then((Response) => Response.json())
  //       .then((findresponse) => {
  //         // console.log(findresponse)
  //         this.setState({
  //           locations: findresponse,
  //         });
  //       });
  //     this.tog_standard();
  //     //alert(loc_id);
  //   }

  getVersionLocation(veh) {
    var veh = veh.target.value;
    if (veh) {
      var parameters = veh.split("~");
      var parameter_index = parameters[0];
      var unit_of_measure = parameters[1];
      var id_description = parameters[2];
    }

    this.setState({
      id_description_graph: id_description,
    });

    // var engine_code_clear = vehicle[2];
    //var desc_id = veh;
    const values = queryString.parse(this.props.location.search);
    const plant_id = values.plant_id;
    const ecu = values.ecu;
    const typeIdk = values.typeidk;
    const engine_code_clear = values.engine_code;
    //console.log(typeIdk + "" + code);

    var body_data = {
      engine_code_clear: engine_code_clear,
      typeIdK: typeIdk,
      plant_id: plant_id,
      parameter_index: parameter_index,
      unit_of_measure: unit_of_measure,
      ecu: ecu,
    };
    const data_source = localStorage.getItem("data_source");
    if (data_source === "internal") {
      var data_source_final = "test_get_parametri_riferimento_graph_data";
    } else {
      var data_source_final = "get_parametri_riferimento_graph_data";
    }

    if (parameter_index && unit_of_measure) {
      fetch(
        config.API +
          // "get_parametri_riferimento_graph_data",
          data_source_final,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Nexion_API_Key: config.API_KEY,
            company: "Nexion",
          },
          body: JSON.stringify(body_data),
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
          var data = findresponse.parameters_reference[0].istogram;
          var bar_ranges = data.map((x) => x.range);
          var bar_values = data.map((x) => x.counter);

          this.setState({
            bar_ranges: bar_ranges,
            bar_values: bar_values,
            gaussion_data: findresponse.parameters_reference[0].gaussion_data,
          });
        });
      this.tog_standard();
    }
  }

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "Version Details Export",
      company: "Nexion",
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  render() {
    const { t } = this.props;
    const bar_options = {
      colors: ["#02a499"],
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: this.state.bar_ranges,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    };

    const bar_series = [
      {
        name: "Aquisition",
        data: this.state.bar_values,
      },
    ];

    const parametri_data = {
      labels: this.state.bar_ranges,
      datasets: [
        {
          label: "Istogram",
          backgroundColor: "#FF7F27",
          borderColor: "#FF7F27",
          borderWidth: 1,
          hoverBackgroundColor: "#FF7F27",
          hoverBorderColor: "#FF7F27",
          data: this.state.bar_values,
        },
      ],
    };

    const parametri_option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            //var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            //var percentage = parseFloat((currentValue / total * 100).toFixed(1));
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const gaussen_config = {
      title: {
        text: null,
      },

      legend: {
        enabled: false,
      },

      xAxis: [
        {
          title: {
            text: "",
          },
          visible: true,
        },
        {
          title: {
            text: this.state.id_description_graph,
          },
          opposite: false,
          visible: true,
        },
      ],

      yAxis: [
        {
          title: {
            text: "",
          },
          visible: true,
        },
        {
          title: {
            text: "",
          },
          opposite: false,
          visible: true,
        },
      ],
      colors: [
        "#006462",
        "#0d233a",
        "#8bbc21",
        "#910000",
        "#1aadce",
        "#492970",
        "#f28f43",
        "#77a1e5",
        "#c42525",
        "#a6c96a",
      ],
      series: [
        {
          name: this.state.id_description_graph,
          type: "bellcurve",
          xAxis: 1,
          yAxis: 1,
          baseSeries: 1,
          intervals: 2,
          zIndex: 2,
          marker: {
            enabled: true,
          },
        },
        {
          name: "Data",
          type: "",
          data: this.state.gaussion_data,
          visible: false,
          marker: {
            radius: 1.5,
          },
          ccessibility: {
            exposeAsGroupOnly: true,
          },
        },
      ],
    };

    const columns = [
      // {
      //   text: t("Id"),
      //   dataField: "id",
      // },
      {
        text: "Impianto",
        dataField: "plant_desc",
      },
      {
        text: "ID Parametro",
        dataField: "description",
      },
      // {
      //   text: "Parametro Index",
      //   dataField: "parameter_index",
      // },
      // {
      //   text: "Unit Of Measure",
      //   dataField: "unit_of_measure",
      // },
      {
        text: "Unit Of Measure",
        dataField: "uom_desc",
      },
      {
        text: "Min Count",
        dataField: "Total",
      },
      {
        text: "Min Avg",
        dataField: "parameter_average",
      },
      {
        text: "Min StdDev",
        dataField: "parameter_delta",
      },
      {
        text: "Graph",
        dataField: "id",
        formatter: (cellContent, row) => {
          // const code = row.code;
          // const TypeIDKN = row.TypeIDKN;
          // const engine_code_clear = row.engine_code_clear;
          const pi = row.parameter_index;
          const uom = row.unit_of_measure;
          const id_description = row.description;
          return (
            <button
              onClick={this.tog_standard && this.getVersionLocation}
              data-toggle="modal"
              value={pi + "~" + uom + "~" + id_description}
              data-target="#myModal"
              className="btn btn-outline-nexion loc_btn lg"
            >
              <i class="ion ion ion-ios-stats"></i>
            </button>
          );
        },
      },
    ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("versiondetailspage.firstPageText"),
      prePageText: t("versiondetailspage.prePageText"),
      nextPageText: t("versiondetailspage.nextPageText"),
      lastPageText: t("versiondetailspage.lastPageText"),
      nextPageTitle: t("versiondetailspage.nextPageTitle"),
      prePageTitle: t("versiondetailspage.prePageTitle"),
      firstPageTitle: t("versiondetailspage.firstPageTitle"),
      lastPageTitle: t("versiondetailspage.lastPageTitle"),
      showTotal: true,
      totalSize: this.state.products.length,
    };

    // const selectRow = {
    //   mode: "checkbox",
    //   clickToSelect: false,
    //   hideSelectAll: true,
    //   onSelect: this.handleOnSelect,
    // };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={columns}
          exportCSV={{ exportAll: false, onlyExportSelection: true }}
        >
          {(props) => (
            <div>
              {/* {this.state.export_btn === true ? (
                <ul className="export_btns">
                  {this.state.export_btn === true ? (
                    <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({
                            btnprimary1: !this.state.btnprimary1,
                          })
                        }
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-success"
                        >
                          {t("versionpage.export")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <ExcelFile
                              element={
                                <span className="react-bs-table-csv-btn btn btn-default">
                                  {t("userspage.exp_sel")}
                                </span>
                              }
                              filename="Versions Details"
                            >
                              <ExcelSheet
                                data={JSON.parse(
                                  localStorage.getItem("VersionSelectedArray")
                                )}
                                name="Versions"
                              >
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn
                                  label="Serial Number"
                                  value="serial_number"
                                />
                                <ExcelColumn
                                  label="Strum."
                                  value="device_name"
                                />
                                <ExcelColumn
                                  label="Password"
                                  value="password"
                                />
                                <ExcelColumn
                                  label="EXP."
                                  value="expiring_date"
                                />
                                <ExcelColumn label="Ling." value="language" />
                                <ExcelColumn
                                  label="Secondary Language"
                                  value="secondary_language"
                                />
                                <ExcelColumn label="Soc" value="company" />
                                <ExcelColumn
                                  label="Installation Date"
                                  value="update_date"
                                />
                                <ExcelColumn
                                  label="Update Result"
                                  value="update_result"
                                />
                                <ExcelColumn
                                  label="Date Added"
                                  value="date_added"
                                />
                                <ExcelColumn
                                  label="OS Version"
                                  value="os_version"
                                />
                                <ExcelColumn label="Ver SW" value="software" />
                                <ExcelColumn
                                  label="Vehicle Selection DB"
                                  value="vehicle_database"
                                />
                                <ExcelColumn
                                  label="OK Fn DB"
                                  value="ok_function_database"
                                />
                                <ExcelColumn
                                  label="Plants DB"
                                  value="plants_database"
                                />
                                <ExcelColumn
                                  label="Lang. DB"
                                  value="language_database"
                                />
                                <ExcelColumn
                                  label="Autodiagnosis"
                                  value="autodiagnosis"
                                />
                                <ExcelColumn
                                  label="Vehicle DB"
                                  value="vehicle_properties"
                                />
                                <ExcelColumn
                                  label="Libraries"
                                  value="library"
                                />
                                <ExcelColumn label="Framework" value="nxevci" />
                                <ExcelColumn
                                  label="Ver Update"
                                  value="sw_update"
                                />
                                <ExcelColumn label="Scripts" value="scripts" />
                                <ExcelColumn label="Kernel" value="kernel" />
                                <ExcelColumn
                                  label="Firmware"
                                  value="firmware"
                                />
                                <ExcelColumn label="Windows" value="windows" />
                                <ExcelColumn label="Android" value="android" />
                                <ExcelColumn
                                  label="Diagnosis"
                                  value="diagnosis"
                                />
                                <ExcelColumn
                                  label="Pass Thru"
                                  value="pass_thru"
                                />
                                <ExcelColumn label="Res1" value="res1" />
                                <ExcelColumn label="Res2" value="res2" />
                                <ExcelColumn label="Res3" value="res3" />
                                <ExcelColumn label="Res4" value="res4" />
                                <ExcelColumn
                                  label="Location"
                                  value="continent_code"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          </DropdownItem>
                          <DropdownItem>
                            <ExcelFile
                              element={
                                <span className="react-bs-table-csv-btn btn btn-default">
                                  {t("userspage.exp_all")}
                                </span>
                              }
                              filename="Versions Details"
                            >
                              <ExcelSheet
                                data={this.state.products}
                                name="Versions"
                              >
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn
                                  label="Serial Number"
                                  value="serial_number"
                                />
                                <ExcelColumn
                                  label="Strum."
                                  value="device_name"
                                />
                                <ExcelColumn
                                  label="Password"
                                  value="password"
                                />
                                <ExcelColumn
                                  label="EXP."
                                  value="expiring_date"
                                />
                                <ExcelColumn label="Ling." value="language" />
                                <ExcelColumn
                                  label="Secondary Language"
                                  value="secondary_language"
                                />
                                <ExcelColumn label="Soc" value="company" />
                                <ExcelColumn
                                  label="Installation Date"
                                  value="update_date"
                                />
                                <ExcelColumn
                                  label="Update Result"
                                  value="update_result"
                                />
                                <ExcelColumn
                                  label="Date Added"
                                  value="date_added"
                                />
                                <ExcelColumn
                                  label="OS Version"
                                  value="os_version"
                                />
                                <ExcelColumn label="Ver SW" value="software" />
                                <ExcelColumn
                                  label="Vehicle Selection DB"
                                  value="vehicle_database"
                                />
                                <ExcelColumn
                                  label="OK Fn DB"
                                  value="ok_function_database"
                                />
                                <ExcelColumn
                                  label="Plants DB"
                                  value="plants_database"
                                />
                                <ExcelColumn
                                  label="Lang. DB"
                                  value="language_database"
                                />
                                <ExcelColumn
                                  label="Autodiagnosis"
                                  value="autodiagnosis"
                                />
                                <ExcelColumn
                                  label="Vehicle DB"
                                  value="vehicle_properties"
                                />
                                <ExcelColumn
                                  label="Libraries"
                                  value="library"
                                />
                                <ExcelColumn label="Framework" value="nxevci" />
                                <ExcelColumn
                                  label="Ver Update"
                                  value="sw_update"
                                />
                                <ExcelColumn label="Scripts" value="scripts" />
                                <ExcelColumn label="Kernel" value="kernel" />
                                <ExcelColumn
                                  label="Firmware"
                                  value="firmware"
                                />
                                <ExcelColumn label="Windows" value="windows" />
                                <ExcelColumn label="Android" value="android" />
                                <ExcelColumn
                                  label="Diagnosis"
                                  value="diagnosis"
                                />
                                <ExcelColumn
                                  label="Pass Thru"
                                  value="pass_thru"
                                />
                                <ExcelColumn label="Res1" value="res1" />
                                <ExcelColumn label="Res2" value="res2" />
                                <ExcelColumn label="Res3" value="res3" />
                                <ExcelColumn label="Res4" value="res4" />
                                <ExcelColumn
                                  label="Location"
                                  value="continent_code"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              ) : (
                ""
              )} */}
              <br />
              <br />
              <hr />
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <BootstrapTable
                striped
                hover
                {...props.baseProps}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive version_det_table"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    // const value = queryString.parse(this.props.location.search);
    // const sn = value.serial_no;
    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>Parametri Details | Nexion Engineering Web Portal</title>
            <meta
              name="description"
              content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background="#016362"
            color={"#ffffff"}
          />
          <br />
          <Modal
            className="modal-xl modal-fs"
            isOpen={this.state.modal_standard}
            toggle={this.tog_standard}
          >
            <div className="modal-body">
              <Card>
                <CardBody>
                  <p>Parametri Istogram</p>
                  <Row>
                    <Col xl={6}>
                      <React.Fragment>
                        {/* <ReactApexChart
                          options={bar_options}
                          series={bar_series}
                          type="line"
                          height="290"
                        /> */}
                        <HighchartsReact
                          constructorType={"chart"}
                          ref={this.chartComponent}
                          highcharts={Highcharts}
                          options={gaussen_config}
                        />
                      </React.Fragment>
                    </Col>
                    <Col xl={6} className="class_istogram_card">
                      <React.Fragment>
                        <Bar
                          width={500}
                          height={250}
                          data={parametri_data}
                          options={parametri_option}
                        />
                      </React.Fragment>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.tog_standard}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
          <br />
          <h4 className="text-center">Parametri Details</h4>
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(ParametriDetails);
