import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import logoSm from "../../assets/images/nexion_sm.png";
import Car from "../../assets/images/car_ac.png";
import i18next from 'i18next';
//import Table from 'react-bootstrap/Table';
//import moment from 'moment';
import {MetaTags} from 'react-meta-tags';
import {Sugar} from 'react-preloaders';
import { withTranslation } from 'react-i18next';
import config from './../../helpers/Constants';

class AcOperationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
        operations:[],
        operations_details:[],
        version:[],
        location:[],
        vehicle:[],
        enabled_features:[],
        customer:[],
        modal_standard: false,
        d_date:'',
        continent_code:'',
        continent_name:'',
        country_code:'',
        country_name:'',
        ip_address:'',
        state_prov:'',
        city:'',
        cus_name:'',
        cus_phone:'',
        cus_address:'',
        loading: true
      };
      i18next.changeLanguage(localStorage.getItem('lang'));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;

  fetch(config.API+'get_user_permissions/'+user_id, {
    headers: {  
      'Nexion_API_Key': config.API_KEY
    }
  }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
    if(findresponses.ac_operations === 0){
      window.history.back();
    }
  })
    
    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const ops_id = values.operation_id;
    if(ops_id === undefined){
        window.history.back();
    }
    fetch(config.API+'ac_man_operations/'+ops_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
        //console.log(findresponse);
      this.setState({
        operations_details:findresponse.operations_details,
        vehicle:findresponse.selected_vehicle,
        version:findresponse.versions,
        operations:findresponse.operations,
        enabled_features:findresponse.enabled_features,
        d_date:findresponse.operations_details.diagnosis_date.replace(/T/, ' ').replace(/\..+/, '')
      })
      if(findresponse.location !== undefined){
      this.setState({
        continent_code:findresponse.location.continent_code,
        continent_name:findresponse.location.continent_name,
        country_code:findresponse.location.country_code,
        country_name:findresponse.location.country_name,
        city:findresponse.location.city,
        state_prov:findresponse.location.state_prov,
        ip_address:findresponse.location.ip_address,
      })
      }
      if(findresponse.customer !== undefined){
          this.setState({
            cus_name:findresponse.customer.name,
            cus_phone:findresponse.customer.telephone,
            cus_address:findresponse.customer.address,
            loading:false
          })
          }
    })
    }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
           <MetaTags>
      <title>AC Manual Operation Report | Nexion Engineering Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#016362" color={'#ffffff'} />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                
                
              </div>
            </Col>
          </Row>

          <Row>
          <div className="col-1">
          </div>
            <div className="col-10 report_class">
              <Card>
                <CardBody className="report_card">
                  <Row>
                    <div className="col-12">
                      <Row>
                      <div className="col-8">
                      <h2 className="font-size-28">Report</h2>
                      </div>
                      <div className="col-4">
                      <div className="invoice-title">
                          <img src={logoSm} className="float-right" alt="logo" height="50" />
                      </div>
                      </div>
                      </Row>
                      <hr />
                      <Row>
                        <div className="col-5">
                        <div className="p-2">
                        <strong><h5>WORKSHOP DATA</h5></strong>
                        </div>

                        <div className="table-responsive">
                        <table className="table table-sm m-0 table-borderless">
                        <tbody>
                        <tr>
                          <td>{t('diagnosisdetailspage.cont_name')}</td>
                          <td>{this.state.continent_name !== undefined && this.state.continent_name !== '' ? this.state.continent_name : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.con_name')}</td>
                          <td>{this.state.country_name !== undefined && this.state.country_name !== '' ? this.state.country_name : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.state')}</td>
                          <td>{this.state.state_prov !== undefined && this.state.state_prov !== '' ? this.state.state_prov : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.city')}</td>
                          <td>{this.state.city !== undefined && this.state.city !== '' ? this.state.city : "N/A"}</td>
                        </tr>

                        <tr>
                        <td>{t('diagnosisdetailspage.cus_name')}</td>
                        <td>{this.state.cus_name !== undefined && this.state.cus_name !== '' ? this.state.cus_name : "N/A"}</td>
                        </tr>

                        <tr>
                        <td>{t('diagnosisdetailspage.cus_phone')}</td>
                        <td>{this.state.cus_phone !== undefined && this.state.cus_phone !== '' ? this.state.cus_phone : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>{t('diagnosisdetailspage.cus_address')}</td>
                          <td>{this.state.cus_address !== undefined && this.state.cus_address !== '' ? this.state.cus_address : "N/A"}</td>
                        </tr>
                        </tbody>
                        </table>
                        </div>
                        </div>

                        <div className="col-5">
                        <div className="p-2">
                        <strong><h5>VERSION</h5></strong>
                        </div>
                        <div className="table-responsive">
                        <table className="table table-sm m-0 table-borderless">
                        <tbody>
                        <tr>
                        <td>Serial Number:</td>
                        <td>{this.state.operations_details.serial_number}</td>
                        </tr>
                        <tr>
                        <td>BOOTLOADER:</td>
                        <td>{this.state.version.bootloader}</td>
                        </tr>
                        <tr>
                        <td>VEHICLE DB:</td>
                        <td>{this.state.version.vehicle_database}</td>
                        </tr>
                        <tr>
                        <td>HMI:</td>
                        <td>{this.state.version.hmi}</td>
                        </tr>
                        <tr>
                        <td>PLC:</td>
                        <td>{this.state.version.plc}</td>
                        </tr>
                        </tbody>
                        </table>
                        </div>
                        </div>
            
                        <div className="col-2">
                          
                        </div>
                      </Row>
                      <br/><br/>
                      <hr />
                      {/* <Row>
                        <div className="col-8">
                        <div className="p-2">
                        <strong><h5>VEHICLE DATA</h5></strong>
                        </div>
                        <div className="table-responsive">
                        <table className="table table-sm m-0 table-borderless">
                        <tbody>
                        <tr>
                        <td>{t('diagnosisdetailspage.brand')}:</td>
                        <td>{this.state.vehicle.brand}</td>
                        </tr>
                        <tr>
                        <td>{t('diagnosisdetailspage.family')}:</td>
                        <td>{this.state.vehicle.family}</td>
                        </tr>
                        <tr>
                        <td>Vehicle Engine:</td>
                        <td> {this.state.vehicle.vehicle_engine}</td>
                        </tr>
                        <tr>
                        <td>Plant:</td>
                        <td> {this.state.vehicle.plant}</td>
                        </tr>
                        <tr>
                        <td>Gas Type:</td>
                        <td> {this.state.vehicle.gas_type}</td>
                        </tr>
                        <tr>
                        <td>Gas Recharge:</td>
                        <td> {this.state.vehicle.gas_recharge}</td>
                        </tr>
                        <tr>
                        <td>Oil Type:</td>
                        <td> {this.state.vehicle.oil_type}</td>
                        </tr>
                        <tr>
                        <td>TypeIDK:</td>
                        <td> {this.state.vehicle.typeidk}</td>
                        </tr>
                        <tr>
                        <td>{t('diagnosisdetailspage.eng_code')}:</td>
                        <td> {this.state.vehicle.engine_code_clear}</td>
                        </tr>
                        </tbody>
                        </table>
                        </div>
                        </div>
                        <div className="col-4">
                        <img src={Car} className="float-right" alt="car"  width="50%"/>
                        </div>
                      </Row> */}
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                          <br/>
                        <div className="p-2">
                          
                          <strong><h5>AC OPERATIONS</h5></strong>
                           
                            <p><b>Operation Date:</b> {this.state.d_date}</p>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                          <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>CMD</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Operation Result</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Gas Insertion</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Gas Recovery</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Oil Insertion</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Oil Recovery</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Vacuum</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Leaks</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                              <tr>
                                  <td className="text-center">{this.state.operations.cmd}</td>
                                  <td className="text-center">{this.state.operations.op_result}</td>
                                  <td className="text-center">{this.state.operations.gas_insertion}</td>
                                  <td className="text-center">{this.state.operations.gas_recovery}</td>
                                  <td className="text-center">{this.state.operations.oil_insertion}</td>
                                  <td className="text-center">{this.state.operations.oil_recovery}</td>
                                  <td className="text-center">{this.state.operations.vacuum}</td>
                                  <td className="text-center">{this.state.operations.leaks}</td>
                                </tr>
                               
                              </tbody>
                            </table>
                          </div>
                          <br/>
                            <br/>
                          <div className="p-2">
                          
                          <strong><h5>ENABLED FEATURES</h5></strong>
                           
                            <p><b>Operation Date:</b> {this.state.d_date}</p>
                        </div>
                          <div className="table-responsive">
                          <table className="table table-sm m-0 table-bordered">
                              <thead>
                                <tr>
                                  <td className="text-center">
                                    <strong>Model</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Gas Type</strong>
                                  </td>
                                  
                                </tr>
                              </thead>
                              <tbody>
                              <tr>
                                  <td className="text-center">{this.state.enabled_features.model}</td>
                                  <td className="text-center">{this.state.enabled_features.gas_type}</td>
                                 
                                </tr>
                               
                              </tbody>
                            </table>
                          </div>

                            <br/>
                            <br/>
                          <div className="d-print-none">
                            <div className="float-right">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.print();
                                }}
                                className="btn btn-mm waves-effect waves-light"
                              >
                                <i className="fa fa-print"> Stampa Report</i>
                              </Link>
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  
                </CardBody>
              </Card>
            </div>
            <div className="col-1">

            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(AcOperationReport);
