import us from "../assets/images/flags/us_flag.jpg";
import spain from "../assets/images/flags/spain_flag.jpg";
import germany from "../assets/images/flags/germany_flag.jpg";
import italy from "../assets/images/flags/italy_flag.jpg";
import russia from "../assets/images/flags/russia_flag.jpg";
import french from "../assets/images/flags/french_flag.jpg";
import portuguese from "../assets/images/flags/portuguese_flag.png";
import romanian from "../assets/images/flags/romanian_flag.png";
import bulgarian from "../assets/images/flags/bulgarian_flag.png";
import hungarian from "../assets/images/flags/hungarian_flag.png";
import czech from "../assets/images/flags/czech.png";
import polish from "../assets/images/flags/polish.png";
import dutch from "../assets/images/flags/dutch.png";


const languages = {
  it: {
    label: "Italian",
    flag: italy,
  },

  en: {
    label: "English",
    flag: us,
  },

  sp: {
    label: "Spanish",
    flag: spain,
  },

  gr: {
    label: "German",
    flag: germany,
  },

  rs: {
    label: "Russian",
    flag: russia,
  },

  fr: {
    label: "French",
    flag: french,
  },
  pt: {
    label: "Portuguese",
    flag: portuguese,
  },
  ro: {
    label: "Romanian",
    flag: romanian,
  },

  bg: {
    label: "Bulgarian",
    flag: bulgarian,
  },

  hu: {
    label: "Hungarian",
    flag: hungarian,
  },

  cs: {
    label: "Czech",
    flag: czech,
  },

  pl: {
    label: "Polish",
    flag: polish
  },

  nl: {
    label: "Dutch",
    flag: dutch
  }
};

export default languages;
