import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class Doughnut extends Component {
    getOption = () => {
        return {

            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)"
            },
            legend: {
                orient: 'horizontal',
                x: 'left',
                data: ['FLEX', 'LOGIC', '300 TIS', 'X3 TIS', 'SOCIO X5 TIS'],
                textStyle: {
                    color: ['#74788d']
                }
            },
            color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf'],
            series: [
                {
                    name: 'Total Tests',
                    type: 'pie',
                    radius: ['50%', '65%'],
                    avoidLabelOverlap: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                fontSize: '10',
                                fontWeight: 'bold'
                            }
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '11',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    textStyle: {
                        fontSize: '10',
                        fontWeight: 'bold'
                    },
                    labelLine: {
                        normal: {
                            show: false,
                            textStyle: {
                                fontSize: '10',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    data: [
                        { value: 335, name: 'FLEX' },
                        { value: 310, name: 'LOGIC' },
                        { value: 234, name: '300 TIS' },
                        { value: 135, name: 'X3 TIS' },
                        { value: 1548, name: 'SOCIO X5 TIS' }
                    ]
                }
            ]
        };
    };
    render() {
        return (
            <React.Fragment>
                <ReactEcharts style={{ height: "270px" }} option={this.getOption()} />
            </React.Fragment>
        );
    }
}
export default Doughnut;