import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { ThemeContext } from "./ThemeContext";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/authUtils";

const firebaseConfig = {
  apiKey: "AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8",
  authDomain: "themesbrand-admin.firebaseapp.com",
  databaseURL: "https://themesbrand-admin.firebaseio.com",
  projectId: "themesbrand-admin",
  storageBucket: "themesbrand-admin.appspot.com",
  messagingSenderId: "427667224207",
  appId: "1:427667224207:web:3b97af80b8b4824619a2fa",
  measurementId: "G-S4LDYNV7FY",
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // theme: "Light",
      theme: localStorage.getItem("theme"),
      toggleThemeHandler: this.toggleThemeHandler,
    };
    this.getLayout = this.getLayout.bind(this);
  }

  // toggleThemeHandler = () => {
  //   this.setState({
  //     theme: this.state.theme === "Light" ? "Dark" : "Light",
  //   });
  //   document.body.classList.add(this.state.theme === "Light" ? "Dark" : "Light")
  // };

  // componentDidMount(){
  //   const options = {
  //     headers: {
  //       Nexion_API_Key: "a102d2c1-df6d-411b-a96d-e95f748e5343",
  //     },
  //   };
  //   axios
  //     .get(
  //       "http://localhost:8080/" + "saveTheme",
  //       options
  //     )
  //     .then((res) => {
  //       this.setState({
  //         theme: res.data,
  //       });
  //     });
  // }
  /**
   * Returns the layout
   */

  toggleThemeHandler = () => {
    localStorage.setItem(
      "themeValue",
      this.state.theme === "Light" ? "Dark" : "Light"
    );
    this.setState({
      theme: localStorage.getItem("themeValue"),
    });
  };

  componentDidMount() {
    window.onload = () => {
      localStorage.setItem("themeValue", "Light");
      this.setState({
        theme: localStorage.getItem("themeValue"),
      });
    };
  }
  componentDidUpdate() {
    window.onload = () => {
      this.setState({
        theme: localStorage.getItem("themeValue"),
      });
    };
  }

  getLayout = () => {
    let layoutCls = VerticalLayout;
    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    document.body.classList.add(this.state.theme || "Light");
    document.body.classList.remove(
      this.state.theme == "Light" ? "Dark" : "Light"
    );

    return (
      <ThemeContext.Provider value={this.state}>
        <React.Fragment>
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </Router>
        </React.Fragment>
      </ThemeContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
