import React from "react";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
//import ToolkitProvider, { CSVExport, ColumnToggle  } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
import axios from "axios";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
//import { Link } from "react-router-dom";
import ReactExport from "react-data-export-fix1";

const { ExportCSVButton } = CSVExport;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

class ExportImportLogs extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      loading: true,
      products: [],
      logged_user: "",
      export_btn: false,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    localStorage.removeItem("ManagementSelectedArray");
    var ManagementSelectedArray = [];
    localStorage.setItem(
      "ManagementSelectedArray",
      JSON.stringify(ManagementSelectedArray)
    );

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    });
    //console.log(user_id);
    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org"
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.versions === 0) {
          window.history.back();
        }
      });

    const days = localStorage.getItem("data_visible_days");
    const selected_product = localStorage.getItem("sel_product");
    if (!selected_product) {
      var sel_product = "all";
    } else {
      sel_product = selected_product;
    }

    fetch(
      config.API +
        "get_export_import_logs?days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          products: findresponse,
          loading: false,
        });
      });
    //console.log(findresponse);
  }

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "Export Logs Export",
      company: "Nexion",
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  handleOnSelect = (row, isSelect) => {
    var selData = JSON.parse(
      localStorage.getItem("ManagementSelectedArray") || "[]"
    );
    function remove(key) {
      var i = selData.findIndex((seldat) => seldat.key === key);
      if (i !== -1) {
        selData.splice(i, 1);
        localStorage.setItem(
          "ManagementSelectedArray",
          JSON.stringify(selData)
        );
      }
    }
    if (isSelect === true) {
      var nkey = row.key;
      var newSelectedArray = JSON.parse(
        localStorage.getItem("ManagementSelectedArray")
      );
      newSelectedArray.push(row);
      localStorage.setItem(
        "ManagementSelectedArray",
        JSON.stringify(newSelectedArray)
      );
    } else {
      remove(row.key);
    }
  };

  render() {
    const { t } = this.props;
    const translation = {
      date: t("tabletitle.date"),
      company: t("tabletitle.company"),
      typology: t("tabletitle.typology"),
      id: t("tabletitle.id"),
      login: t("tabletitle.login"),
      note: t("tabletitle.note")
    }
    const columns = [
      {
        text: translation.id,
        dataField: "id",
      },
      {
        text: translation.date,
        dataField: "date_added",
        formatter: (rowContent, row) => {
          // var up_date = row.date_added.replace(/T/, ' ').replace(/\..+/, '');
          const addedDate = row.date_added.split("T")[0];
          const localDateTime = new Date(row.date_added)
            .toLocaleString()
            .split(", ")[1];
          const up_date = ` ${addedDate} ${localDateTime}`;
          return up_date;
        },
      },
      {
        text: translation.login,
        dataField: "username",
      },
      {
        text: translation.company,
        dataField: "company",
      },

      {
        text: translation.typology,
        dataField: "type",
      },
      {
        text: translation.note,
        dataField: "note",
      },
    ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("versiondetailspage.firstPageText"),
      prePageText: t("versiondetailspage.prePageText"),
      nextPageText: t("versiondetailspage.nextPageText"),
      lastPageText: t("versiondetailspage.lastPageText"),
      nextPageTitle: t("versiondetailspage.nextPageTitle"),
      prePageTitle: t("versiondetailspage.prePageTitle"),
      firstPageTitle: t("versiondetailspage.firstPageTitle"),
      lastPageTitle: t("versiondetailspage.lastPageTitle"),
      showTotal: true,
      totalSize: this.state.products.length,
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectAll: true,
      onSelect: this.handleOnSelect,
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "Export Import Logs.csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                {this.state.export_btn === true ? (
                  <li>
                    <Dropdown
                      isOpen={this.state.btnprimary1}
                      direction="left"
                      toggle={() =>
                        this.setState({ btnprimary1: !this.state.btnprimary1 })
                      }
                    >
                      <DropdownToggle tag="button" className="btn btn-success">
                        {t("versionpage.export")}
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem onClick={this.handleExportSubmit}>
                          <ExportCSVButton {...props.csvProps}>
                            {t("versionpage.exp_sel")}
                          </ExportCSVButton>
                        </DropdownItem>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <CSVLink
                            className="react-bs-table-csv-btn btn btn-default"
                            filename={
                              "Version Data " + this.state.date + ".csv"
                            }
                            data={this.state.products}
                          >
                            {t("versionpage.exp_all")}
                          </CSVLink>
                        </DropdownItem> */}
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_sel")}
                              </span>
                            }
                            filename="Export Logs Data"
                          >
                            <ExcelSheet
                              data={JSON.parse(
                                localStorage.getItem("ManagementSelectedArray")
                              )}
                              name="Users"
                            >
                              <ExcelColumn label="ID" value="id" />
                              <ExcelColumn label="username" value="username" />
                              <ExcelColumn label="company" value="company" />
                              <ExcelColumn label="type" value="type" />
                              <ExcelColumn label="note" value="note" />
                              <ExcelColumn
                                label="date_added"
                                value="date_added"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_all")}
                              </span>
                            }
                            filename="Export Logs Data"
                          >
                            <ExcelSheet data={this.state.products} name="Users">
                              <ExcelColumn label="ID" value="id" />
                              <ExcelColumn label="username" value="username" />
                              <ExcelColumn label="company" value="company" />
                              <ExcelColumn label="type" value="type" />
                              <ExcelColumn label="note" value="note" />
                              <ExcelColumn
                                label="date_added"
                                value="date_added"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <br />
              <br />
              <hr />
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <BootstrapTable
                striped
                hover
                {...props.baseProps}
                selectRow={selectRow}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive version_det_table"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>Export Logs | Nexion Engineering Web Portal</title>
            <meta
              name="description"
              content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background="#016362"
            color={"#ffffff"}
          />

          <h4 className="text-center">{t("menus.export_logs")}</h4>
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(ExportImportLogs);
