import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";

//i18n
import i18next from "i18next";
import languages from "../common/languages";

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("it");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    // localStorage.setItem("lang", "it");
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    setSelectedLang(lang);
  };

  const toggle = () => {
    setMenu(!menu);
  };
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-none d-md-block ms-2"
      >
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${localStorage.getItem("lang")}.flag`)}
            alt="Veltrix"
            height="16"
            className="me-2"
          />{" "}
          {get(languages, `${localStorage.getItem("lang")}.label`)}{" "}
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Veltrix"
                className="me-2"
                height="12"
              />
              <span className="align-middle ml-2">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
