import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import config from "./../../helpers/Constants";

// const SidebarContent = props => {

//   return (

//     <div id="sidebar-menu">
//       <ul className="metismenu list-unstyled" id="side-menu">
//         <li className="menu-title">Main Menu</li>

//         <li>
//           <Link to="/dashboard" className="waves-effect">
//             <i className="ti-home"></i>
//             <span>{t('menus.home')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/dashboard" className="waves-effect">
//             <i className="ion ion-ios-speedometer"></i>
//             <span>{t('menus.dashboard')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-time"></i>
//             <span>{t('menus.logs')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-person"></i>
//             <span>{t('menus.users')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-construct"></i>
//             <span>{t('menus.parts')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-cog"></i>
//             <span>{t('menus.parameters')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-alert"></i>
//             <span>{t('menus.error_all')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-bug"></i>
//             <span>{t('menus.eobd_errors')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-medkit"></i>
//             <span>{t('menus.diagnosis')}</span>
//           </Link>
//         </li>

//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-list"></i>
//             <span>{t('menus.versions')}</span>
//           </Link>
//         </li>
//         <li>
//           <Link to="/#" className=" waves-effect">
//             <i className="ion ion-ios-key"></i>
//             <span>{t('menus.secure_gateway')}</span>
//           </Link>
//         </li>
//       </ul>
//     </div>
//   );
// };

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosis_visiblity: false,
      version_visiblity: false,
      diagnosis_test_visiblity: false,
      version_test_visiblity: false,
      ac_operations_visiblity: false,
      ac_version_visiblity: false,
      users_visiblity: false,
      tickets_visibility: false,
    };
    this.state = { value: localStorage.getItem("lang") };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    let selected_product = localStorage.getItem("sel_product");
    // console.log("user_id");
    // console.log(user_id);

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        //console.log(findresponses);

        if (findresponses.users === 1) {
          this.setState({
            users_visiblity: true,
          });
        }
        if (findresponses.diagnosis === 1) {
          this.setState({
            diagnosis_visiblity: true,
          });
        }
        if (findresponses.versions === 1) {
          this.setState({
            version_visiblity: true,
          });
        }
        if (findresponses.diag_test === 1) {
          this.setState({
            diagnosis_test_visiblity: true,
          });
        }
        if (findresponses.ver_test === 1) {
          this.setState({
            version_test_visiblity: true,
          });
        }
        if (findresponses.ac_version === 1) {
          this.setState({
            ac_version_visiblity: true,
          });
        } else {
          this.setState({
            ac_version_visiblity: false,
          });
        }
        if (findresponses.ac_operations === 1) {
          this.setState({
            ac_operations_visiblity: true,
          });
        } else {
          this.setState({
            ac_operations_visiblity: false,
          });
        }
        if (selected_product === "MM") {
          this.setState({
            ac_version_visiblity: false,
            ac_operations_visiblity: false,
          });
        }
        if (findresponses.tickets === 1) {
          this.setState({
            tickets_visibility: true,
          });
        } else {
          this.setState({
            tickets_visibility: false,
          });
        }
      });
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    if (this.props.type !== "condensed" || this.props.isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">Main Menu</li>

                <li>
                  <Link to="/home" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{t("menus.home")}</span>
                  </Link>
                </li>
                {this.state.users_visiblity === true ? (
                  <li>
                    <Link to="/users" className="waves-effect">
                      <i className="ion ion-ios-person"></i>
                      <span>{t("menus.users")}</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {this.state.users_visiblity === true ? (
                  <li>
                    <Link to="/logs" className="waves-effect">
                      <i className="ion ion-ios-time"></i>
                      <span>{t("menus.logs")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/site_access_logs" className=" waves-effect">
                          <i className="ion ion-ios-log-in"></i>
                          <span>{t("menus.site_access")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/user_management_logs"
                          className=" waves-effect"
                        >
                          <i className="ion ion-ios-person"></i>
                          <span>{t("menus.user_management")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/export_import_logs"
                          className=" waves-effect"
                        >
                          <i className="ion ion-ios-download"></i>
                          <span>{t("menus.export")}</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {this.state.diagnosis_visiblity === true ? (
                  <li>
                    <Link
                      to="/auto_diagnosis"
                      aria-expanded="ture"
                      className="waves-effect"
                    >
                      <i className="ion ion-ios-medkit"></i>
                      <span>{t("menus.auto_diagnosis_menu")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      {this.state.users_visiblity === true ? (
                        <li>
                          <Link to="/dashboard" className="waves-effect">
                            <i className="ion ion-ios-speedometer"></i>
                            <span>{t("menus.dashboard")}</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="diag_menu_class">
                        <Link to="/diagnosis" className=" waves-effect">
                          <i className="ion ion-ios-medkit"></i>
                          <span>{t("menus.diagnosis")}</span>
                        </Link>
                        {/* <ul className="sub-menu new-sub-menu">
                          <li>
                            <Link to="/diagnosis_all" className=" waves-effect">
                              <span>{t("diagnosispage.all")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/diagnosis_test"
                              className=" waves-effect"
                            >
                              <span>{t("diagnosispage.internal")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/diagnosis" className=" waves-effect">
                              <span>{t("diagnosispage.customers")}</span>
                            </Link>
                          </li>
                        </ul> */}
                      </li>
                      <li className="ver_menu_class">
                        <Link to="/versions" className=" waves-effect">
                          <i className="ion ion-ios-list"></i>
                          <span>{t("menus.versions")}</span>
                        </Link>
                        {/* <ul className="sub-menu ver-new-sub-menu">
                          <li>
                            <Link to="/versions_all" className=" waves-effect">
                              <span>{t("diagnosispage.all")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/versions_test" className=" waves-effect">
                              <span>{t("diagnosispage.internal")}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/versions" className=" waves-effect">
                              <span>{t("diagnosispage.customers")}</span>
                            </Link>
                          </li>
                        </ul> */}
                      </li>
                      {this.state.tickets_visibility === true ? (
                        <li className="ver_menu_class">
                          <Link to="/tickets_list" className=" waves-effect">
                            <i className="mdi mdi-jira"></i>
                            <span>{t("menus.tickets_menu")}</span>
                          </Link>
                          <ul className="sub-menu ver-new-sub-menu">
                            <li>
                              <Link to="/tickets" className=" waves-effect">
                                <span>{t("menus.tickets_page")}</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/tickets_dashboard"
                                className=" waves-effect"
                              >
                                <span>{t("menus.tickets_dashboard")}</span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <Link to="/parametri" className="waves-effect">
                          <i className="ion ion ion-ios-cog"></i>
                          <span>Parametri</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {this.state.ac_version_visiblity === true ? (
                  <li>
                    <Link
                      to="/ac_diagnosis"
                      aria-expanded="ture"
                      className="waves-effect"
                    >
                      <i className="mdi mdi-air-conditioner"></i>
                      <span>{t("menus.ac_menu")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      {this.state.ac_operations_visiblity === true ? (
                        <li>
                          <Link to="/ac_dashboard" className="waves-effect">
                            <i className="ion ion-ios-speedometer"></i>
                            <span>{t("menus.ac_dashboard")}</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {this.state.ac_version_visiblity === true ? (
                        <li>
                          <Link
                            to="/ac_machine_operations"
                            className="waves-effect"
                          >
                            <i className="mdi mdi-air-conditioner"></i>
                            <span>{t("menus.ac_machine_operations")}</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {this.state.ac_version_visiblity === true ? (
                        <li>
                          <Link to="/ac_versions" className="waves-effect">
                            <i className="ion ion-ios-list"></i>
                            <span>{t("menus.ac_version")}</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {this.state.ac_operations_visiblity === true ? (
                        <li>
                          <Link to="/ac_operations" className="waves-effect">
                            <i className="mdi mdi-air-conditioner"></i>
                            <span>{t("menus.ac_auto_operations")}</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {this.state.ac_operations_visiblity === true ? (
                        <li>
                          <Link
                            to="/ac_man_operations"
                            className="waves-effect"
                          >
                            <i className="mdi mdi-air-conditioner"></i>
                            <span>{t("menus.ac_manual_operations")}</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </li>
                ) : (
                  ""
                )}
                {/* <li>
                  <Link to="/software_version" className="waves-effect">
                    <i className="ti-home"></i>
                    <span>{t("menus.software_version")}</span>
                  </Link>
                </li> */}
              </ul>
            </div>
          </SimpleBar>
        ) : (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/home" className="waves-effect">
                  <i className="ti-home"></i>
                  <span>{t("menus.home")}</span>
                </Link>
              </li>

              {this.state.users_visiblity === true ? (
                <li>
                  <Link to="/users" className="has-arrow waves-effect">
                    <i className="ion ion-ios-person"></i>
                    <span>{t("menus.users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/users" className=" waves-effect">
                        <i className="ion ion-ios-person"></i>
                        <span>{t("menus.users")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/add_user" className=" waves-effect">
                        <i className="ion ion-md-person-add"></i>
                        <span>{t("menus.add_user")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )}
              {this.state.users_visiblity === true ? (
                <li>
                  <Link to="/logs" className="has-arrow waves-effect">
                    <i className="ion ion-ios-time"></i>
                    <span>{t("menus.logs")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/site_access_logs" className=" waves-effect">
                        <i className="ion ion-ios-log-in"></i>
                        <span>{t("menus.site_access")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/user_management_logs"
                        className=" waves-effect"
                      >
                        <i className="ion ion-ios-person"></i>
                        <span>{t("menus.user_management")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/export_import_logs" className=" waves-effect">
                        <i className="ion ion-ios-download"></i>
                        <span>{t("menus.export")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )}
              {this.state.diagnosis_visiblity === true ? (
                <li>
                  <Link
                    to="/auto_diagnosis"
                    aria-expanded="ture"
                    className="waves-effect"
                  >
                    <i className="ion ion-ios-medkit"></i>
                    <span>{t("menus.auto_diagnosis_menu")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    {this.state.users_visiblity === true ? (
                      <li>
                        <Link to="/dashboard" className="waves-effect">
                          <i className="ion ion-ios-speedometer"></i>
                          <span>{t("menus.dashboard")}</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link to="/diagnosis_list" className=" waves-effect">
                        <i className="ion ion-ios-medkit"></i>
                        <span>{t("menus.diagnosis")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/versions_list" className=" waves-effect">
                        <i className="ion ion-ios-list"></i>
                        <span>{t("menus.versions")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/tickets" className=" waves-effect">
                        <span>{t("menus.tickets_page")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/parametri" className="waves-effect">
                        <i className="ion ion ion-ios-cog"></i>
                        <span>Parametri</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )}
              {this.state.ac_version_visiblity === true ? (
                <li>
                  <Link
                    to="/ac_diagnosis"
                    aria-expanded="ture"
                    className="waves-effect"
                  >
                    <i className="mdi mdi-air-conditioner"></i>
                    <span>{t("menus.ac_menu")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    {this.state.ac_operations_visiblity === true ? (
                      <li>
                        <Link to="/ac_dashboard" className="waves-effect">
                          <i className="ion ion-ios-speedometer"></i>
                          <span>{t("menus.ac_dashboard")}</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.ac_version_visiblity === true ? (
                      <li>
                        <Link
                          to="/ac_machine_operations"
                          className="waves-effect"
                        >
                          <i className="mdi mdi-air-conditioner"></i>
                          <span>{t("menus.ac_machine_operations")}</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.ac_version_visiblity === true ? (
                      <li>
                        <Link to="/ac_versions" className="waves-effect">
                          <i className="ion ion-ios-list"></i>
                          <span>{t("menus.ac_version")}</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.ac_operations_visiblity === true ? (
                      <li>
                        <Link to="/ac_operations" className="waves-effect">
                          <i className="mdi mdi-air-conditioner"></i>
                          <span>{t("menus.ac_auto_operations")}</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.ac_operations_visiblity === true ? (
                      <li>
                        <Link to="/ac_man_operations" className="waves-effect">
                          <i className="mdi mdi-air-conditioner"></i>
                          <span>{t("menus.ac_manual_operations")}</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              ) : (
                ""
              )}
              {/* <li>
                <Link to="/software_version" className="waves-effect">
                  <i className="ti-home"></i>
                  <span>{t("menus.software_version")}</span>
                </Link>
              </li> */}
            </ul>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Sidebar));
