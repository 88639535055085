import React from "react";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
//import ToolkitProvider, { CSVExport, ColumnToggle  } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import queryString from "query-string";
import { MetaTags } from "react-meta-tags";
import moment from "moment";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
import axios from "axios";
import {
  Card,
  CardBody,
  Modal,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import ReactExport from "react-data-export-fix1";
//import { Link } from "react-router-dom";

const { ExportCSVButton } = CSVExport;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

class VersionDetails extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      modal_standard: false,
      loading: true,
      products: [],
      locations: [],
      logged_user: "",
      export_btn: false,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.tog_standard = this.tog_standard.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.getVersionLocation = this.getVersionLocation.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  tog_standard(loc) {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    localStorage.removeItem("VersionSelectedArray");
    var VersionSelectedArray = [];
    localStorage.setItem(
      "VersionSelectedArray",
      JSON.stringify(VersionSelectedArray)
    );

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    });
    //console.log(user_id);
    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org"
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.versions === 0) {
          window.history.back();
        }
      });

    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const sr_n = values.serial_no;
    //const sn_no = params.get('serial_no');
    //const version_id = this.props.location.query.id;
    fetch(config.API + "versions_test/" + sr_n, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          products: findresponse,
          loading: false,
        });
      });
    //console.log(findresponse);
  }

  getVersionLocation(id) {
    var loc_id = id.target.value;
    fetch(config.API + "version_location_test/" + loc_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse)
        this.setState({
          locations: findresponse,
        });
      });
    this.tog_standard();
    //alert(loc_id);
  }

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "Version Details Export",
      company: "Nexion",
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  handleOnSelect = (row, isSelect) => {
    var selData = JSON.parse(
      localStorage.getItem("VersionSelectedArray") || "[]"
    );
    function remove(key) {
      var i = selData.findIndex((seldat) => seldat.key === key);
      if (i !== -1) {
        selData.splice(i, 1);
        localStorage.setItem("VersionSelectedArray", JSON.stringify(selData));
      }
    }
    if (isSelect === true) {
      var nkey = row.key;
      var newSelectedArray = JSON.parse(
        localStorage.getItem("VersionSelectedArray")
      );
      newSelectedArray.push(row);
      localStorage.setItem(
        "VersionSelectedArray",
        JSON.stringify(newSelectedArray)
      );
    } else {
      remove(row.key);
    }
  };

  render() {
    const { t } = this.props;
    const columns = [
      {
        text: t("versiondetailspage.int_date"),
        dataField: "update_date",
        formatter: (rowContent, row) => {
          var up_date = row.update_date.replace(/T/, " ").replace(/\..+/, "");
          return up_date;
        },
      },
      {
        text: t("versiondetailspage.strum"),
        dataField: "device_name",
      },
      {
        text: t("versiondetailspage.soc"),
        dataField: "company",
      },
      {
        text: t("versiondetailspage.fam"),
        isDummyField: true,
        dataField: "continent_code",
        formatter: (cellContent, row) => {
          if (row.continent_code !== "" && row.continent_code !== null) {
            return (
              <button
                onClick={this.tog_standard && this.getVersionLocation}
                data-toggle="modal"
                value={row.id}
                data-target="#myModal"
                className="btn btn-outline-nexion loc_btn lg"
              >
                {t("versiondetailspage.loc")}
              </button>
            );
          } else {
            return <span>N/A</span>;
          }
        },
      },
      {
        text: t("versiondetailspage.ling"),
        dataField: "language",
      },
      {
        text: t("versiondetailspage.cks"),
        isDummyField: true,
        dataField: "update_result",
        formatter: (cellContent, row) => {
          if (row.update_result === 1) {
            return (
              <h5>
                <span className="label label-success">OK</span>
              </h5>
            );
          } else {
            return (
              <h5>
                <span className="label label-danger">NOK</span>
              </h5>
            );
          }
        },
      },
      {
        text: t("versiondetailspage.exp"),
        dataField: "expiring_date",
        formatter: (rowContent, row) => {
          const up_date = moment(row.expiring_date).format("YYYY-MM-DD");
          //const id = row.id;
          return up_date;
        },
      },
      {
        text: t("versiondetailspage.password"),
        dataField: "password",
      },
      {
        text: t("versiondetailspage.ver_soft"),
        dataField: "software",
        formatter: (cellContent, row) => {
          if (row.u_software === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.software}</span>
              </span>
            );
          } else {
            return row.software;
          }
        },
      },
      {
        text: "Vehicle Selection DB",
        dataField: "vehicle_database",
        formatter: (cellContent, row) => {
          if (row.u_vehicle_database === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">
                  {row.vehicle_database}
                </span>
              </span>
            );
          } else {
            return row.vehicle_database;
          }
        },
      },
      {
        text: "Vehicle DB",
        dataField: "vehicle_properties",
        formatter: (cellContent, row) => {
          if (row.u_vehicle_properties === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">
                  {row.vehicle_properties}
                </span>
              </span>
            );
          } else {
            return row.vehicle_properties;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_ok"),
        dataField: "ok_function_database",
        formatter: (cellContent, row) => {
          if (row.u_ok_function_database === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">
                  {row.ok_function_database}
                </span>
              </span>
            );
          } else {
            return row.ok_function_database;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_plant"),
        dataField: "plants_database",
        formatter: (cellContent, row) => {
          if (row.u_plants_database === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.plants_database}</span>
              </span>
            );
          } else {
            return row.plants_database;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_lang"),
        dataField: "language_database",
        formatter: (cellContent, row) => {
          if (row.u_language_database === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">
                  {row.language_database}
                </span>
              </span>
            );
          } else {
            return row.language_database;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_auto"),
        dataField: "autodiagnosis",
        formatter: (cellContent, row) => {
          if (row.u_autodiagnosis === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.autodiagnosis}</span>
              </span>
            );
          } else {
            return row.autodiagnosis;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_update"),
        dataField: "sw_update",
        formatter: (cellContent, row) => {
          if (row.u_sw_update === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.sw_update}</span>
              </span>
            );
          } else {
            return row.sw_update;
          }
        },
      },
      {
        text: "Framework",
        dataField: "nxevci",
        formatter: (cellContent, row) => {
          if (row.u_nxevci === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.nxevci}</span>
              </span>
            );
          } else {
            return row.nxevci;
          }
        },
      },
      {
        text: "Libraries",
        dataField: "library",
        formatter: (cellContent, row) => {
          if (row.u_library === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.library}</span>
              </span>
            );
          } else {
            return row.library;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_scripts"),
        dataField: "scripts",
        formatter: (cellContent, row) => {
          if (row.u_scripts === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.scripts}</span>
              </span>
            );
          } else {
            return row.scripts;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_kernel"),
        dataField: "kernel",
        formatter: (cellContent, row) => {
          if (row.u_kernel === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.kernel}</span>
              </span>
            );
          } else {
            return row.kernel;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_fw"),
        dataField: "firmware",
        formatter: (cellContent, row) => {
          if (row.u_firmware === 1) {
            return (
              <span>
                <i className="ion ion-md-cloud-upload updated_icon"></i>{" "}
                <span className="updated_icon_text">{row.firmware}</span>
              </span>
            );
          } else {
            return row.firmware;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_windows"),
        dataField: "windows",
        formatter: (cellContent, row) => {
          if (row.windows === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_android"),
        dataField: "android",
        formatter: (cellContent, row) => {
          if (row.android === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_diagnosis"),
        dataField: "diagnosis",
        formatter: (cellContent, row) => {
          if (row.diagnosis === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_pass"),
        dataField: "pass_thru",
        formatter: (cellContent, row) => {
          if (row.pass_thru === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_res1"),
        dataField: "res1",
        formatter: (cellContent, row) => {
          if (row.res1 === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_res2"),
        dataField: "res2",
        formatter: (cellContent, row) => {
          if (row.res2 === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_res3"),
        dataField: "res3",
        formatter: (cellContent, row) => {
          if (row.res3 === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_res4"),
        dataField: "res4",
        formatter: (cellContent, row) => {
          if (row.res4 === 1) {
            return <i className="fa fa-check result_success_icons"></i>;
          } else {
            return <i className="fa fa-times result_failed_icons"></i>;
          }
        },
      },
      {
        text: t("versiondetailspage.ver_os"),
        dataField: "os_version",
        formatter: (cellContent, row) => {
          if (row.os_version === "android") {
            return <i className="ion ion-logo-android android_logo"></i>;
          } else if (row.os_version === "windows") {
            return <i className="ion ion-logo-windows windows_logo"></i>;
          } else {
            return <span>Undefined</span>;
          }
        },
      },
    ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("versiondetailspage.firstPageText"),
      prePageText: t("versiondetailspage.prePageText"),
      nextPageText: t("versiondetailspage.nextPageText"),
      lastPageText: t("versiondetailspage.lastPageText"),
      nextPageTitle: t("versiondetailspage.nextPageTitle"),
      prePageTitle: t("versiondetailspage.prePageTitle"),
      firstPageTitle: t("versiondetailspage.firstPageTitle"),
      lastPageTitle: t("versiondetailspage.lastPageTitle"),
      showTotal: true,
      totalSize: this.state.products.length,
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectAll: true,
      onSelect: this.handleOnSelect,
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={columns}
          exportCSV={{ exportAll: false, onlyExportSelection: true }}
        >
          {(props) => (
            <div>
              {this.state.export_btn === true ? (
                <ul className="export_btns">
                  {this.state.export_btn === true ? (
                    <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({
                            btnprimary1: !this.state.btnprimary1,
                          })
                        }
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-success"
                        >
                          {t("versionpage.export")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <ExcelFile
                              element={
                                <span className="react-bs-table-csv-btn btn btn-default">
                                  {t("userspage.exp_sel")}
                                </span>
                              }
                              filename="Versions Details"
                            >
                              <ExcelSheet
                                data={JSON.parse(
                                  localStorage.getItem("VersionSelectedArray")
                                )}
                                name="Versions"
                              >
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn
                                  label="Serial Number"
                                  value="serial_number"
                                />
                                <ExcelColumn
                                  label="Strum."
                                  value="device_name"
                                />
                                <ExcelColumn
                                  label="Password"
                                  value="password"
                                />
                                <ExcelColumn
                                  label="EXP."
                                  value="expiring_date"
                                />
                                <ExcelColumn label="Ling." value="language" />
                                <ExcelColumn
                                  label="Secondary Language"
                                  value="secondary_language"
                                />
                                <ExcelColumn label="Soc" value="company" />
                                <ExcelColumn
                                  label="Installation Date"
                                  value="update_date"
                                />
                                <ExcelColumn
                                  label="Update Result"
                                  value="update_result"
                                />
                                <ExcelColumn
                                  label="Date Added"
                                  value="date_added"
                                />
                                <ExcelColumn
                                  label="OS Version"
                                  value="os_version"
                                />
                                <ExcelColumn label="Ver SW" value="software" />
                                <ExcelColumn
                                  label="Vehicle Selection DB"
                                  value="vehicle_database"
                                />
                                <ExcelColumn
                                  label="OK Fn DB"
                                  value="ok_function_database"
                                />
                                <ExcelColumn
                                  label="Plants DB"
                                  value="plants_database"
                                />
                                <ExcelColumn
                                  label="Lang. DB"
                                  value="language_database"
                                />
                                <ExcelColumn
                                  label="Autodiagnosis"
                                  value="autodiagnosis"
                                />
                                <ExcelColumn
                                  label="Vehicle DB"
                                  value="vehicle_properties"
                                />
                                <ExcelColumn
                                  label="Libraries"
                                  value="library"
                                />
                                <ExcelColumn label="Framework" value="nxevci" />
                                <ExcelColumn
                                  label="Ver Update"
                                  value="sw_update"
                                />
                                <ExcelColumn label="Scripts" value="scripts" />
                                <ExcelColumn label="Kernel" value="kernel" />
                                <ExcelColumn
                                  label="Firmware"
                                  value="firmware"
                                />
                                <ExcelColumn label="Windows" value="windows" />
                                <ExcelColumn label="Android" value="android" />
                                <ExcelColumn
                                  label="Diagnosis"
                                  value="diagnosis"
                                />
                                <ExcelColumn
                                  label="Pass Thru"
                                  value="pass_thru"
                                />
                                <ExcelColumn label="Res1" value="res1" />
                                <ExcelColumn label="Res2" value="res2" />
                                <ExcelColumn label="Res3" value="res3" />
                                <ExcelColumn label="Res4" value="res4" />
                                <ExcelColumn
                                  label="Location"
                                  value="continent_code"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          </DropdownItem>
                          <DropdownItem>
                            <ExcelFile
                              element={
                                <span className="react-bs-table-csv-btn btn btn-default">
                                  {t("userspage.exp_all")}
                                </span>
                              }
                              filename="Versions Details"
                            >
                              <ExcelSheet
                                data={this.state.products}
                                name="Versions"
                              >
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn
                                  label="Serial Number"
                                  value="serial_number"
                                />
                                <ExcelColumn
                                  label="Strum."
                                  value="device_name"
                                />
                                <ExcelColumn
                                  label="Password"
                                  value="password"
                                />
                                <ExcelColumn
                                  label="EXP."
                                  value="expiring_date"
                                />
                                <ExcelColumn label="Ling." value="language" />
                                <ExcelColumn
                                  label="Secondary Language"
                                  value="secondary_language"
                                />
                                <ExcelColumn label="Soc" value="company" />
                                <ExcelColumn
                                  label="Installation Date"
                                  value="update_date"
                                />
                                <ExcelColumn
                                  label="Update Result"
                                  value="update_result"
                                />
                                <ExcelColumn
                                  label="Date Added"
                                  value="date_added"
                                />
                                <ExcelColumn
                                  label="OS Version"
                                  value="os_version"
                                />
                                <ExcelColumn label="Ver SW" value="software" />
                                <ExcelColumn
                                  label="Vehicle Selection DB"
                                  value="vehicle_database"
                                />
                                <ExcelColumn
                                  label="OK Fn DB"
                                  value="ok_function_database"
                                />
                                <ExcelColumn
                                  label="Plants DB"
                                  value="plants_database"
                                />
                                <ExcelColumn
                                  label="Lang. DB"
                                  value="language_database"
                                />
                                <ExcelColumn
                                  label="Autodiagnosis"
                                  value="autodiagnosis"
                                />
                                <ExcelColumn
                                  label="Vehicle DB"
                                  value="vehicle_properties"
                                />
                                <ExcelColumn
                                  label="Libraries"
                                  value="library"
                                />
                                <ExcelColumn label="Framework" value="nxevci" />
                                <ExcelColumn
                                  label="Ver Update"
                                  value="sw_update"
                                />
                                <ExcelColumn label="Scripts" value="scripts" />
                                <ExcelColumn label="Kernel" value="kernel" />
                                <ExcelColumn
                                  label="Firmware"
                                  value="firmware"
                                />
                                <ExcelColumn label="Windows" value="windows" />
                                <ExcelColumn label="Android" value="android" />
                                <ExcelColumn
                                  label="Diagnosis"
                                  value="diagnosis"
                                />
                                <ExcelColumn
                                  label="Pass Thru"
                                  value="pass_thru"
                                />
                                <ExcelColumn label="Res1" value="res1" />
                                <ExcelColumn label="Res2" value="res2" />
                                <ExcelColumn label="Res3" value="res3" />
                                <ExcelColumn label="Res4" value="res4" />
                                <ExcelColumn
                                  label="Location"
                                  value="continent_code"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              ) : (
                ""
              )}
              <br />
              <br />
              <hr />
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <BootstrapTable
                striped
                hover
                {...props.baseProps}
                selectRow={selectRow}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive version_det_table"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    const value = queryString.parse(this.props.location.search);
    const sn = value.serial_no;
    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>Version Details | Nexion Engineering Web Portal</title>
            <meta
              name="description"
              content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background="#016362"
            color={"#ffffff"}
          />
          <br />
          <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
            <div className="modal-body">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>IP Address:</b> {this.state.locations.ip_address}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>Continent Code:</b>{" "}
                        {this.state.locations.continent_code}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>Continent Name:</b>{" "}
                        {this.state.locations.continent_name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>Country Code:</b> {this.state.locations.country_code}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>Country Name:</b> {this.state.locations.country_name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>State Prov:</b> {this.state.locations.state_prov}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>City:</b> {this.state.locations.city}
                      </p>
                    </Col>
                    <Col md={6}></Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.tog_standard}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
          <br />
          <h4 className="text-center">
            {t("versiondetailspage.version_rec")} -{" "}
            <span className="ver_head">{sn}</span>
          </h4>
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(VersionDetails);
