import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import { MetaTags } from "react-meta-tags";
import "../../assets/scss/custom.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

import "chartist/dist/scss/chartist.scss";
import { Sugar } from "react-preloaders";

class LogsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { value: localStorage.getItem("lang"), loading: false };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Logs | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Nexion Engineering"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="container-fluid">
          <Row className="homepageFirstRow">
            <Col xl={4}>
              <Card>
                <Link to="/site_access_logs" className="home_link">
                  <CardBody className="text-center homeIconCardBody">
                    <i class="ion ion-ios-log-in text-nexion text-center homeIcon"></i>
                    <p>
                      <span>{t("menus.site_access")}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <Link to="/user_management_logs" className="home_link">
                  <CardBody className="text-center homeIconCardBody">
                    <b class="ion ion-ios-person text-nexion text-center homeIcon"></b>
                    <p>
                      <span>{t("menus.user_management")}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <Link to="/export_import_logs" className="home_link">
                  <CardBody className="text-center homeIconCardBody">
                    <b class="ion ion-ios-download text-nexion text-center homeIcon"></b>
                    <p>
                      <span>{t("menus.export")}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(LogsPage);
