import React from 'react'
import '../../assets/scss/custom.scss'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
//import ToolkitProvider, { CSVExport, ColumnToggle  } from 'react-bootstrap-table2-toolkit';
import { MetaTags } from 'react-meta-tags'
import { Sugar } from 'react-preloaders'
import config from './../../helpers/Constants'
import axios from 'axios'
import { Card, CardBody } from 'reactstrap'
//import { Link } from "react-router-dom";

class SoftwareVersion extends React.Component {
  constructor(props) {
    super(props)
    //this.state = {};
    this.state = {
      value: localStorage.getItem('lang'),
      loading: true,
      softwareVersionList: [],
      logged_user: '',
      export_btn: false,
    }
    i18next.changeLanguage(localStorage.getItem('lang'))
  }
  removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  show() {
    this.setState({ visible: true })
  }
  hide() {
    this.setState({ visible: false })
  }

  componentDidMount() {
    let decodedString = localStorage.getItem('authUser')
    let authUser = JSON.parse(atob(decodedString))
    var user_id = authUser.id
    var user_name = authUser.username
    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    })
    //console.log(user_id);
    if (
      authUser.role === 'Administrator' ||
      authUser.role === 'Diagnosis Manager' ||
      authUser.role === 'Administrator and Site Org'
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      })
    }

    fetch(config.API + 'get_user_permissions/' + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.versions === 0) {
          window.history.back()
        }
      })

    fetch(config.API + 'software_version/', {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          softwareVersionList: findresponse.softwareVersionList.map(
            (obj, index) => {
              return {
                ...obj,
                id: index + 1,
              }
            }
          ),
          loading: false,
        })
      })
    //console.log(findresponse);
  }

  add_software_version() {
    window.location.replace('add_software_version')
  }

  render() {
    const { t } = this.props
    const columns = [
      {
        text: 'Id',
        dataField: 'id',
      },
      {
        text: t('add_software_version.frontend'),
        dataField: 'frontend',
      },
      {
        text: t('add_software_version.backend'),
        dataField: 'backend',
      },
      {
        text: t('add_software_version.database'),
        dataField: 'db',
      },
      {
        text: t('add_software_version.publish_date'),
        dataField: 'publish_date',
        formatter: (rowContent, row) => {
          const date = row.publish_date ? row.publish_date.split('T')[0] : ''
          return date
        },
      },
      {
        text: t('add_software_version.remarks'),
        dataField: 'remarks',
      },
    ]
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('versiondetailspage.firstPageText'),
      prePageText: t('versiondetailspage.prePageText'),
      nextPageText: t('versiondetailspage.nextPageText'),
      lastPageText: t('versiondetailspage.lastPageText'),
      nextPageTitle: t('versiondetailspage.nextPageTitle'),
      prePageTitle: t('versiondetailspage.prePageTitle'),
      firstPageTitle: t('versiondetailspage.firstPageTitle'),
      lastPageTitle: t('versiondetailspage.lastPageTitle'),
      showTotal: true,
      totalSize: this.state.softwareVersionList.length,
    }

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className='container-fluid'>
        <ToolkitProvider
          keyField='id'
          data={this.state.softwareVersionList}
          columns={columns}
        >
          {(props) => (
            <div>
              <ul className='export_btns'>
                <li>
                  <button
                    type='button'
                    onClick={this.add_software_version}
                    className='btn filter_btn waves-effect waves-light'
                  >
                    <i className='ti-plus'></i>{' '}
                    {t('software_version_page.add_software_version')}
                  </button>
                </li>
              </ul>
              <br />
              <br />
              <hr />
              <BootstrapTable
                striped
                hover
                {...props.baseProps}
                // selectRow={selectRow}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses='table-responsive version_det_table'
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    )
    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>Software Versions | Nexion Engineering Web Portal</title>
            <meta
              name='description'
              content='Diagnostic Portal For Technomotor and Magneti Marelli Instruments'
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background='#016362'
            color={'#ffffff'}
          />

          <h4 className='text-center'>{t('menus.software_version')}</h4>
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </CardBody>
      </Card>
    )
  }
}

export default withTranslation()(SoftwareVersion)
