import React, { Component } from "react";

// falgs
//import usFlag from "../assets/images/flags/us_flag.jpg";
//import italyFlag from "../assets/images/flags/italy_flag.jpg";
import { withTranslation } from "react-i18next";
import i18next from "i18next";

class DataSourceDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: localStorage.getItem("data_source"),
      allDropdown: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data) {
    localStorage.setItem("data_source", data.target.value);
    window.location.reload(false);
    console.log(data.target.value, "from the data dropdown");
    this.setState({ value: data.target.value });
  }

  render() {
    return (
      <React.Fragment>
        {/* <img
              className="mr-2"
              src={usFlag}
              alt="Header Language"
              height="16"
            /> */}
        <select
          className="btn header-item waves-effecte form-control lang_select"
          value={this.state.value}
          onChange={this.handleChange}
        >
          {this.props.allData ? (
            <option
              className="notify-item align-middle form-control"
              value="all"
            >
              {" "}
              All{" "}
            </option>
          ) : null}

          <option
            className="notify-item align-middle form-control"
            value="production"
          >
            {" "}
            Production{" "}
          </option>
          <option
            className="notify-item align-middle form-control"
            value="internal"
          >
            {" "}
            Internal{" "}
          </option>
        </select>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DataSourceDropdown);
