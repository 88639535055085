import {
  ADD_SOFTWARE_VERSION,
  ADD_SOFTWARE_VERSION_SUCCESSFUL,
  ADD_SOFTWARE_VERSION_FAILED,
} from './actionTypes'

export const addSoftwareVersion = (softwareVersionData) => {
  return {
    type: ADD_SOFTWARE_VERSION,
    payload: { softwareVersionData },
  }
}

export const addSoftwareVersionSuccessful = (softwareVersionData) => {
  return {
    type: ADD_SOFTWARE_VERSION_SUCCESSFUL,
    payload: softwareVersionData,
  }
}

export const addSoftwareVersionFailed = (softwareVersionData) => {
  return {
    type: ADD_SOFTWARE_VERSION_FAILED,
    payload: softwareVersionData,
  }
}
