import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { Progress } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { VectorMap } from "react-jvectormap";
import "../../assets/scss/custom.scss";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { MetaTags } from "react-meta-tags";
import "chartist/dist/scss/chartist.scss";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
import LoadingOverlay from "react-loading-overlay";
import PacmanLoader from "react-spinners/PacmanLoader";
import "../../css/printTicketDashboard.css";

const progressBar = {
  height: "5px",
};
const countryName = {
  float: "left",
};
const countryCount = {
  float: "right",
};

const countryLine = {
  display: "flow-root",
  marginBottom: "0px",
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      daysvalue: localStorage.getItem("data_visible_days"),
      loading: true,
      isActive: true,
      t_div_by_type_total_counts_bugs_count: 0,
      t_div_by_type_total_counts_new_feat_count: 0,
      t_div_by_type_total_counts_improvement_count: 0,
      t_div_by_type_total_counts_task_count: 0,
      t_div_by_type_total_counts_new_func_count: 0,
      t_div_by_type_open_counts_bugs_count: 0,
      t_div_by_type_open_counts_new_feat_count: 0,
      t_div_by_type_open_counts_improvement_count: 0,
      t_div_by_type_open_counts_task_count: 0,
      t_div_by_type_open_counts_new_func_count: 0,
      t_div_by_type_status_RICEVUTA: 0,
      t_div_by_type_status_IN_ANALISI: 0,
      t_div_by_type_status_t_IN_LAVORAZIONE: 0,
      t_div_by_type_status_CANCELLATA: 0,
      t_div_by_type_status_IN_TEST: 0,
      t_div_by_type_status_IN_ATTESA_DI_RILASCIO: 0,
      t_div_by_type_status_RILASCIATA: 0,
      t_div_by_type_status_RIFIUTATA: 0,
      t_div_by_type_status_IN_ATTESA: 0,
      t_div_by_type_status_NUOVO_SVILUPPO: 0,
      t_div_by_type_label_bug_SINOTTICO: 0,
      t_div_by_type_label_bug_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_bug_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_bug_DATABASE: 0,
      t_div_by_type_label_bug_STAR: 0,
      t_div_by_type_label_bug_ERRORI: 0,
      t_div_by_type_label_bug_PARAMETRI_STATI: 0,
      t_div_by_type_label_bug_VIN: 0,
      t_div_by_type_label_bug_EOBD: 0,
      t_div_by_type_label_bug_open_SINOTTICO: 0,
      t_div_by_type_label_bug_open_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_bug_open_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_bug_open_DATABASE: 0,
      t_div_by_type_label_bug_open_STAR: 0,
      t_div_by_type_label_bug_open_ERRORI: 0,
      t_div_by_type_label_bug_open_PARAMETRI_STATI: 0,
      t_div_by_type_label_bug_open_VIN: 0,
      t_div_by_type_label_bug_open_EOBD: 0,
      t_div_by_type_label_new_feat_SINOTTICO: 0,
      t_div_by_type_label_new_feat_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_new_feat_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_new_feat_DATABASE: 0,
      t_div_by_type_label_new_feat_STAR: 0,
      t_div_by_type_label_new_feat_ERRORI: 0,
      t_div_by_type_label_new_feat_PARAMETRI_STATI: 0,
      t_div_by_type_label_new_feat_VIN: 0,
      t_div_by_type_label_new_feat_EOBD: 0,
      t_div_by_type_label_new_feat_open_SINOTTICO: 0,
      t_div_by_type_label_new_feat_open_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_new_feat_open_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_new_feat_open_DATABASE: 0,
      t_div_by_type_label_new_feat_open_STAR: 0,
      t_div_by_type_label_new_feat_open_ERRORI: 0,
      t_div_by_type_label_new_feat_open_PARAMETRI_STATI: 0,
      t_div_by_type_label_new_feat_open_VIN: 0,
      t_div_by_type_label_new_feat_open_EOBD: 0,
      t_div_by_type_label_imp_SINOTTICO: 0,
      t_div_by_type_label_imp_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_imp_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_imp_DATABASE: 0,
      t_div_by_type_label_imp_STAR: 0,
      t_div_by_type_label_imp_ERRORI: 0,
      t_div_by_type_label_imp_PARAMETRI_STATI: 0,
      t_div_by_type_label_imp_VIN: 0,
      t_div_by_type_label_imp_EOBD: 0,
      t_div_by_type_label_imp_open_SINOTTICO: 0,
      t_div_by_type_label_imp_open_NUOVA_IMPLEMENTAZIONE: 0,
      t_div_by_type_label_imp_open_ATTIVAZIONI_CODIFICHE: 0,
      t_div_by_type_label_imp_open_DATABASE: 0,
      t_div_by_type_label_imp_open_STAR: 0,
      t_div_by_type_label_imp_open_ERRORI: 0,
      t_div_by_type_label_imp_open_PARAMETRI_STATI: 0,
      t_div_by_type_label_imp_open_VIN: 0,
      t_div_by_type_label_imp_open_EOBD: 0,
      bug_items: "",
      imp_items: "",
      nf_items: "",
      task_items: "",
      new_func_items: "",
      open_tickets: 0,
      closed_tickets: 0,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var data_visible_days = localStorage.getItem("data_visible_days");

    this.setState({
      sum_au_daysvalue: data_visible_days,
      sum_ap_daysvalue: data_visible_days,
      sum_all_ap_daysvalue: data_visible_days,
      sum_os_daysvalue: data_visible_days,
    });

    if (!data_visible_days) {
      var days = "14";
    } else {
      days = data_visible_days;
    }

    const selected_product = localStorage.getItem("sel_product");
    if (!selected_product) {
      var sel_product = "all";
    } else {
      sel_product = selected_product;
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        //console.log(findresponses);
        this.setState({
          loading: false,
        });
        if (findresponses.tickets !== 0) {
        } else {
          window.history.back();
        }
      });

    fetch(
      // config.API + "get_tickets_dash_tick_div_by_type_total?company=Nexion",
      config.API +
        "get_tickets_dash_tick_div_by_type_total?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_total_counts_bugs_count: findresponse.bugs_count,
          t_div_by_type_total_counts_improvement_count:
            findresponse.improvement_count,
          t_div_by_type_total_counts_new_feat_count:
            findresponse.new_feat_count,
          t_div_by_type_total_counts_new_func_count: findresponse.new_func,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_type_open?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          t_div_by_type_open_counts_bugs_count: findresponse.bugs_count,
          t_div_by_type_open_counts_improvement_count:
            findresponse.improvement_count,
          t_div_by_type_open_counts_new_feat_count: findresponse.new_feat_count,
          t_div_by_type_open_counts_new_func_count: findresponse.new_func,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_status?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_status_RICEVUTA: findresponse.RICEVUTA,
          t_div_by_type_status_IN_ANALISI: findresponse.IN_ANALISI,
          t_div_by_type_status_t_IN_LAVORAZIONE: findresponse.IN_LAVORAZIONE,
          t_div_by_type_status_CANCELLATA: findresponse.CANCELLATA,
          t_div_by_type_status_IN_TEST: findresponse.IN_TEST,
          t_div_by_type_status_IN_ATTESA_DI_RILASCIO:
            findresponse.IN_ATTESA_DI_RILASCIO,
          t_div_by_type_status_RILASCIATA: findresponse.RILASCIATA,
          t_div_by_type_status_RIFIUTATA: findresponse.RIFIUTATA,
          t_div_by_type_status_IN_ATTESA: findresponse.IN_ATTESA,
          t_div_by_type_status_NUOVO_SVILUPPO: findresponse.NUOVO_SVILUPPO,
        });
      });

    //Bugs
    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_bugs?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_bug_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_bug_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_bug_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_bug_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_bug_STAR: findresponse.STAR,
          t_div_by_type_label_bug_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_bug_PARAMETRI_STATI: findresponse.PARAMETRI_STATI,
          t_div_by_type_label_bug_VIN: findresponse.VIN,
          t_div_by_type_label_bug_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_bugs_open?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_bug_open_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_bug_open_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_bug_open_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_bug_open_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_bug_open_STAR: findresponse.STAR,
          t_div_by_type_label_bug_open_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_bug_open_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_bug_open_VIN: findresponse.VIN,
          t_div_by_type_label_bug_open_EOBD: findresponse.EOBD,
        });
      });

    //New Feat
    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_new_feat?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_new_feat_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_new_feat_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_new_feat_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_new_feat_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_new_feat_STAR: findresponse.STAR,
          t_div_by_type_label_new_feat_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_new_feat_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_new_feat_VIN: findresponse.VIN,
          t_div_by_type_label_new_feat_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_new_feat_open?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_new_feat_open_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_new_feat_open_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_new_feat_open_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_new_feat_open_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_new_feat_open_STAR: findresponse.STAR,
          t_div_by_type_label_new_feat_open_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_new_feat_open_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_new_feat_open_VIN: findresponse.VIN,
          t_div_by_type_label_new_feat_open_EOBD: findresponse.EOBD,
        });
      });

    // Improvement
    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_improvement?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_imp_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_imp_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_imp_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_imp_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_imp_STAR: findresponse.STAR,
          t_div_by_type_label_imp_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_imp_PARAMETRI_STATI: findresponse.PARAMETRI_STATI,
          t_div_by_type_label_imp_VIN: findresponse.VIN,
          t_div_by_type_label_imp_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_labels_improvement_open?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          t_div_by_type_label_imp_open_SINOTTICO: findresponse.SINOTTICO,
          t_div_by_type_label_imp_open_NUOVA_IMPLEMENTAZIONE:
            findresponse.NUOVA_IMPLEMENTAZIONE,
          t_div_by_type_label_imp_open_ATTIVAZIONI_CODIFICHE:
            findresponse.ATTIVAZIONI_CODIFICHE,
          t_div_by_type_label_imp_open_DATABASE: findresponse.DATABASE,
          t_div_by_type_label_imp_open_STAR: findresponse.STAR,
          t_div_by_type_label_imp_open_ERRORI: findresponse.ERRORI,
          t_div_by_type_label_imp_open_PARAMETRI_STATI:
            findresponse.PARAMETRI_STATI,
          t_div_by_type_label_imp_open_VIN: findresponse.VIN,
          t_div_by_type_label_imp_open_EOBD: findresponse.EOBD,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_div_by_days?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          d_list: findresponse.diagnosis_items.d_list,
          bug_items: findresponse.diagnosis_items.bug_items,
          imp_items: findresponse.diagnosis_items.imp_items,
          nf_items: findresponse.diagnosis_items.nf_items,
          new_func_items: findresponse.diagnosis_items.new_func_items,
          isActive: false,
        });
      });

    fetch(
      config.API +
        "get_tickets_dash_tick_open_closed?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          open_tickets: findresponse.Open_Tickets,
          closed_tickets: findresponse.Closed_Tickets,
        });
      });
  }

  render() {
    const { t } = this.props;

    // New Dashboard Section

    const divided_by_type_options_total = {
      colors: ["#e81123", "#C3C852", "#016362", "#009e49"],
      labels: ["BUG", "MIGLIORAMENTO", "NUOVO SVILUPPO", "NUOVO FUNZIONALITA"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        fontSize: "11px",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              fontSize: "11px",
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_total_counts_bugs_count,
        this.state.t_div_by_type_total_counts_improvement_count,
        this.state.t_div_by_type_total_counts_new_feat_count,
        this.state.t_div_by_type_total_counts_new_func_count,
      ],
    };

    const divided_by_type_options_open = {
      colors: ["#e81123", "#C3C852", "#016362", "#009e49"],
      labels: ["BUG", "MIGLIORAMENTO", "NUOVO SVILUPPO", "NUOVO FUNZIONALITA"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_open_counts_bugs_count,
        this.state.t_div_by_type_open_counts_improvement_count,
        this.state.t_div_by_type_open_counts_new_feat_count,
        this.state.t_div_by_type_open_counts_new_func_count,
      ],
    };

    const divided_by_type_status_options = {
      colors: [
        "#fff100",
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "RICEVUTA",
        "IN ANALISI",
        "IN LAVORAZIONE",
        "CANCELLATA",
        "IN TEST",
        "IN ATTESA DI RILASCIO",
        "RILASCIATA",
        "RIFIUTATA",
        "IN ATTESA",
        "NUOVO SVILUPPO",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_status_RICEVUTA,
        this.state.t_div_by_type_status_IN_ANALISI,
        this.state.t_div_by_type_status_t_IN_LAVORAZIONE,
        this.state.t_div_by_type_status_CANCELLATA,
        this.state.t_div_by_type_status_IN_TEST,
        this.state.t_div_by_type_status_IN_ATTESA_DI_RILASCIO,
        this.state.t_div_by_type_status_RILASCIATA,
        this.state.t_div_by_type_status_RIFIUTATA,
        this.state.t_div_by_type_status_IN_ATTESA,
        this.state.t_div_by_type_status_NUOVO_SVILUPPO,
      ],
    };

    const divided_by_type_open_close_options = {
      colors: ["#00bcf2", "#009e49"],
      labels: ["OPEN", "CLOSED"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [this.state.open_tickets, this.state.closed_tickets],
    };

    const divided_by_type_label_bugs_options_total = {
      colors: [
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "SINOTTICO",
        "NUOVA_IMPLEMENTAZIONE",
        "ATTIVAZIONI_CODIFICHE",
        "DATABASE",
        "STAR",
        "ERRORI",
        "PARAMETRI_STATI",
        "VIN",
        "EOBD",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_label_bug_SINOTTICO,
        this.state.t_div_by_type_label_bug_NUOVA_IMPLEMENTAZIONE,
        this.state.t_div_by_type_label_bug_ATTIVAZIONI_CODIFICHE,
        this.state.t_div_by_type_label_bug_DATABASE,
        this.state.t_div_by_type_label_bug_STAR,
        this.state.t_div_by_type_label_bug_ERRORI,
        this.state.t_div_by_type_label_bug_PARAMETRI_STATI,
        this.state.t_div_by_type_label_bug_VIN,
        this.state.t_div_by_type_label_bug_EOBD,
      ],
    };

    const divided_by_type_label_bugs_options_open = {
      colors: [
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "SINOTTICO",
        "NUOVA_IMPLEMENTAZIONE",
        "ATTIVAZIONI_CODIFICHE",
        "DATABASE",
        "STAR",
        "ERRORI",
        "PARAMETRI_STATI",
        "VIN",
        "EOBD",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_label_bug_open_SINOTTICO,
        this.state.t_div_by_type_label_bug_open_NUOVA_IMPLEMENTAZIONE,
        this.state.t_div_by_type_label_bug_open_ATTIVAZIONI_CODIFICHE,
        this.state.t_div_by_type_label_bug_open_DATABASE,
        this.state.t_div_by_type_label_bug_open_STAR,
        this.state.t_div_by_type_label_bug_open_ERRORI,
        this.state.t_div_by_type_label_bug_open_PARAMETRI_STATI,
        this.state.t_div_by_type_label_bug_open_VIN,
        this.state.t_div_by_type_label_bug_open_EOBD,
      ],
    };

    const divided_by_type_label_new_feat_options_total = {
      colors: [
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "SINOTTICO",
        "NUOVA_IMPLEMENTAZIONE",
        "ATTIVAZIONI_CODIFICHE",
        "DATABASE",
        "STAR",
        "ERRORI",
        "PARAMETRI_STATI",
        "VIN",
        "EOBD",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_label_new_feat_SINOTTICO,
        this.state.t_div_by_type_label_new_feat_NUOVA_IMPLEMENTAZIONE,
        this.state.t_div_by_type_label_new_feat_ATTIVAZIONI_CODIFICHE,
        this.state.t_div_by_type_label_new_feat_DATABASE,
        this.state.t_div_by_type_label_new_feat_STAR,
        this.state.t_div_by_type_label_new_feat_ERRORI,
        this.state.t_div_by_type_label_new_feat_PARAMETRI_STATI,
        this.state.t_div_by_type_label_new_feat_VIN,
        this.state.t_div_by_type_label_new_feat_EOBD,
      ],
    };

    const divided_by_type_label_new_feat_options_open = {
      colors: [
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "SINOTTICO",
        "NUOVA_IMPLEMENTAZIONE",
        "ATTIVAZIONI_CODIFICHE",
        "DATABASE",
        "STAR",
        "ERRORI",
        "PARAMETRI_STATI",
        "VIN",
        "EOBD",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_label_new_feat_open_SINOTTICO,
        this.state.t_div_by_type_label_new_feat_open_NUOVA_IMPLEMENTAZIONE,
        this.state.t_div_by_type_label_new_feat_open_ATTIVAZIONI_CODIFICHE,
        this.state.t_div_by_type_label_new_feat_open_DATABASE,
        this.state.t_div_by_type_label_new_feat_open_STAR,
        this.state.t_div_by_type_label_new_feat_open_ERRORI,
        this.state.t_div_by_type_label_new_feat_open_PARAMETRI_STATI,
        this.state.t_div_by_type_label_new_feat_open_VIN,
        this.state.t_div_by_type_label_new_feat_open_EOBD,
      ],
    };

    const divided_by_type_label_imp_options_total = {
      colors: [
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "SINOTTICO",
        "NUOVA_IMPLEMENTAZIONE",
        "ATTIVAZIONI_CODIFICHE",
        "DATABASE",
        "STAR",
        "ERRORI",
        "PARAMETRI_STATI",
        "VIN",
        "EOBD",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_label_imp_SINOTTICO,
        this.state.t_div_by_type_label_imp_NUOVA_IMPLEMENTAZIONE,
        this.state.t_div_by_type_label_imp_ATTIVAZIONI_CODIFICHE,
        this.state.t_div_by_type_label_imp_DATABASE,
        this.state.t_div_by_type_label_imp_STAR,
        this.state.t_div_by_type_label_imp_ERRORI,
        this.state.t_div_by_type_label_imp_PARAMETRI_STATI,
        this.state.t_div_by_type_label_imp_VIN,
        this.state.t_div_by_type_label_imp_EOBD,
      ],
    };

    const divided_by_type_label_imp_options_open = {
      colors: [
        "#ff8c00",
        "#e81123",
        "#ec008c",
        "#68217a",
        "#00188f",
        "#00bcf2",
        "#00b294",
        "#009e49",
        "#bad80a",
      ],
      labels: [
        "SINOTTICO",
        "NUOVA_IMPLEMENTAZIONE",
        "ATTIVAZIONI_CODIFICHE",
        "DATABASE",
        "STAR",
        "ERRORI",
        "PARAMETRI_STATI",
        "VIN",
        "EOBD",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "11px",
            },
          },
        },
      ],
      series: [
        this.state.t_div_by_type_label_imp_open_SINOTTICO,
        this.state.t_div_by_type_label_imp_open_NUOVA_IMPLEMENTAZIONE,
        this.state.t_div_by_type_label_imp_open_ATTIVAZIONI_CODIFICHE,
        this.state.t_div_by_type_label_imp_open_DATABASE,
        this.state.t_div_by_type_label_imp_open_STAR,
        this.state.t_div_by_type_label_imp_open_ERRORI,
        this.state.t_div_by_type_label_imp_open_PARAMETRI_STATI,
        this.state.t_div_by_type_label_imp_open_VIN,
        this.state.t_div_by_type_label_imp_open_EOBD,
      ],
    };

    const sum_os_options = {
      colors: ["#e81123", "#C3C852", "#016362", "#009e49"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex];
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "20%",
        },
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },

      xaxis: {
        categories: this.state.d_list,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontSize: "11px",
      },
      series: [
        {
          name: "BUG",
          data: this.state.bug_items,
        },
        {
          name: "MIGLIORAMENTO",
          data: this.state.imp_items,
        },
        {
          name: "NUOVO SVILUPPO",
          data: this.state.nf_items,
        },
        {
          name: "NUOVO FUNZIONALITA",
          data: this.state.new_func_items,
        },
      ],
    };

    return (
      <React.Fragment>
        <MetaTags>
          <title>Tickets Dashboard | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />

        <div className="container-fluid">
          <h5 className="stats_name">{t("menus.tickets_dashboard")}</h5>

          <Row className="homepageFirstRow">
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.divided_by_type_total")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_options_total}
                      series={divided_by_type_options_total.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.divided_by_type_open")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_options_open}
                      series={divided_by_type_options_open.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.divided_by_status")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_status_options}
                      series={divided_by_type_status_options.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.open_closed_tickets")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_open_close_options}
                      series={divided_by_type_open_close_options.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>
                    {t("jira_tickets_page.label_bugs_total")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_label_bugs_options_total}
                      series={divided_by_type_label_bugs_options_total.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.label_bugs_open")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_label_bugs_options_open}
                      series={divided_by_type_label_bugs_options_open.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.label_imp_total")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_label_imp_options_total}
                      series={divided_by_type_label_imp_options_total.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.label_imp_open")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_label_imp_options_open}
                      series={divided_by_type_label_imp_options_open.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.label_nf_total")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_label_new_feat_options_total}
                      series={
                        divided_by_type_label_new_feat_options_total.series
                      }
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.label_nf_open")}
                  </h4>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      className="print"
                      options={divided_by_type_label_new_feat_options_open}
                      series={
                        divided_by_type_label_new_feat_options_open.series
                      }
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/* <Col xl={1}></Col> */}
            <Col xl={12} sm={12} md={12}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title print">
                    <i className="ion ion-ios-speedometer text-nexion"></i>{" "}
                    {t("jira_tickets_page.divide_by_type_day")}
                  </h4>
                  <LoadingOverlay
                    active={this.state.isActive}
                    spinner
                    text={t("jira_tickets_page.loading_text")}
                  >
                    <br />
                    <br />
                    <React.Fragment>
                      <ReactApexChart
                        className="print"
                        options={sum_os_options}
                        series={sum_os_options.series}
                        type="bar"
                        height="290"
                      />
                    </React.Fragment>
                  </LoadingOverlay>
                </CardBody>
                <br />
                <br />
                <div className="d-print-none">
                  <div className="float-left">
                    <Link
                      to="tickets_dashboard_report"
                      target="_blank"
                      className="btn vis_report btn-mm waves-effect waves-light"
                    >
                      <i className="ion ion-md-document"> Visualizza Report</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        window.print();
                      }}
                      className="btn btn-mm waves-effect waves-light"
                    >
                      <i className="fa fa-print"> Stampa Grafico Report</i>
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Dashboard);
