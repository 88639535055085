import React from "react";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
//import moment from 'moment';
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
} from "reactstrap";
import ReactExport from "react-data-export-fix1";
import PulseLoader from "react-spinners/PulseLoader";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

const { ExportCSVButton } = CSVExport;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

const roles = {
  "Administrator and Site Org": "Administrator & Site Org",
  Administrator: "Administrator",
  "AC Operator": "AC Operator",
  Assistance: "Assistance",
  "Diagnosis Developer": "Diagnosis Developer",
  "Diagnosis Tester": "Diagnosis Tester",
  "Diagnosis Manager": "Diagnosis Manager",
  Commercial: "Commercial",
};

const socselectOptions = {
  MM: "Magneti Marelli",
  TM: "Tecnomotor",
  Nexion: "Nexion",
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      //value: localStorage.getItem('lang'),
      users: [],
      success_msg: false,
      confirm_msg: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      confirm_both: false,
      delete_user_id: "",
      img_html: false,
      loading: true,
      logged_user: "",
      logged_username: "",
      date: new Date(),
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("ManagementSelectedArray");
    var ManagementSelectedArray = [];
    localStorage.setItem(
      "ManagementSelectedArray",
      JSON.stringify(ManagementSelectedArray)
    );

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(user_id);
    this.setState({
      logged_user: user_id,
      logged_username: user_name,
    });

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.users === 0) {
          window.history.back();
        }
      });

    fetch(config.API + "users?company=Nexion", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          users: findresponse,
          loading: false,
        });
      });
  }
  add_user() {
    window.location.replace("add_user");
  }

  openDeleteDialog(id) {
    var user_id = id.target.value;
    this.setState({ delete_user_id: user_id, confirm_both: true });
  }

  deleteUser() {
    var user_id = this.state.delete_user_id;
    const username = this.state.logged_username;
    fetch(config.API + "delete_user/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        if (findresponse.status === "success") {
          this.setState({ success_msg: true });
        } else {
          this.setState({ img_html: true });
        }
      });
  }

  delete_success() {
    window.location.reload(false);
  }

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "Users All Export",
      company: "Nexion",
    };
    const username = this.state.logged_username;
    const options = {
      headers: {
        nexion_api_key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs/", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  handleOnSelect = (row, isSelect) => {
    var selData = JSON.parse(
      localStorage.getItem("ManagementSelectedArray") || "[]"
    );
    function remove(key) {
      var i = selData.findIndex((seldat) => seldat.key === key);
      if (i !== -1) {
        selData.splice(i, 1);
        localStorage.setItem(
          "ManagementSelectedArray",
          JSON.stringify(selData)
        );
      }
    }
    if (isSelect === true) {
      var nkey = row.key;
      var newSelectedArray = JSON.parse(
        localStorage.getItem("ManagementSelectedArray")
      );
      newSelectedArray.push(row);
      localStorage.setItem(
        "ManagementSelectedArray",
        JSON.stringify(newSelectedArray)
      );
    } else {
      remove(row.key);
    }
  };

  render() {
    const { t } = this.props;
    const columns = [
      // {
      //     text: t('userspage.user_id'),
      //     dataField: "id",
      //     classes: "selcheck",
      //     filter: textFilter(),
      //   },

      {
        text: t("userspage.firstname"),
        dataField: "firstname",
        filter: textFilter(),
      },
      {
        text: t("userspage.lastname"),
        dataField: "lastname",
        filter: textFilter(),
      },
      {
        text: t("userspage.role"),
        dataField: "role",
        filter: selectFilter({
          options: roles,
        }),
      },
      {
        text: t("userspage.email"),
        dataField: "email",
        filter: textFilter(),
      },
      {
        text: t("userspage.address"),
        dataField: "address",
        filter: textFilter(),
      },
      {
        text: t("userspage.soc"),
        dataField: "soc",
        filter: selectFilter({
          options: socselectOptions,
        }),
      },
      // {
      //     text: t('userspage.ling'),
      //     dataField: "ling"
      //   }
      //   ,
      {
        text: t("userspage.edit"),
        dataField: "id",
        formatter: (rowContent, row) => {
          //const sn_no = row.id;
          const id = row.id;
          return (
            <Link to={"/edit_user?id=" + id}>
              <button className="btn btn-outline-nexion btn lg">
                {t("userspage.view")}
              </button>
            </Link>
          );
        },
      },
      {
        text: t("userspage.delete"),
        dataField: "id",
        formatter: (rowContent, row) => {
          if (row.id === this.state.logged_user) {
            return (
              <Button color="warning" value={row.id} id="sa-params">
                <i className="ion ion-md-close-circle-outline"></i>{" "}
                {t("userspage.not_allow")}
              </Button>
            );
          } else {
            return (
              <Button
                color="danger"
                value={row.id}
                id="sa-params"
                onClick={this.openDeleteDialog}
              >
                <i className="ion ion-md-close-circle-outline"></i>
              </Button>
            );
          }
        },
      },
    ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("userspage.firstPageText"),
      prePageText: t("userspage.prePageText"),
      nextPageText: t("userspage.nextPageText"),
      lastPageText: t("userspage.lastPageText"),
      nextPageTitle: t("userspage.nextPageTitle"),
      prePageTitle: t("userspage.prePageTitle"),
      firstPageTitle: t("userspage.firstPageTitle"),
      lastPageTitle: t("userspage.lastPageTitle"),
      showTotal: true,
      totalSize: this.state.users.length,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectAll: true,
      onSelect: this.handleOnSelect,
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        {this.state.confirm_both ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.deleteUser()}
            onCancel={() =>
              this.setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelled",
                dynamic_description: "User is Not Removed",
              })
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}
        {this.state.success_msg ? (
          <SweetAlert
            title="Good job!"
            success
            showCancel
            confirmBtnBsStyle="success"
            onConfirm={() => this.delete_success()}
          >
            User Deleted Successfully
          </SweetAlert>
        ) : null}
        {this.state.img_html ? (
          <SweetAlert
            title={<span>Failed!</span>}
            warning
            onConfirm={() => this.setState({ img_html: false })}
          >
            Failed to Delete User
          </SweetAlert>
        ) : null}
        <ToolkitProvider
          keyField="id"
          data={this.state.users}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "Users Data " + this.state.date + ".csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                <li>
                  <button
                    type="button"
                    onClick={this.add_user}
                    className="btn filter_btn waves-effect waves-light"
                  >
                    <i className="ti-plus"></i> {t("userspage.add_user")}
                  </button>
                </li>
                <li>
                  <Dropdown
                    isOpen={this.state.btnprimary1}
                    direction="left"
                    toggle={() =>
                      this.setState({ btnprimary1: !this.state.btnprimary1 })
                    }
                  >
                    <DropdownToggle tag="button" className="btn btn-success">
                      {t("userspage.export")}
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem>
                        <ExportCSVButton {...props.csvProps}>
                          {t("userspage.exp_sel")}
                        </ExportCSVButton>
                      </DropdownItem>
                      <DropdownItem>
                        <CSVLink
                          className="react-bs-table-csv-btn btn btn-default"
                          filename={"Users Data " + this.state.date + ".csv"}
                          data={this.state.users}
                        >
                          {t("userspage.exp_all")}
                        </CSVLink>
                      </DropdownItem> */}
                      <DropdownItem onClick={this.handleExportSubmit}>
                        <ExcelFile
                          element={
                            <span className="react-bs-table-csv-btn btn btn-default">
                              {t("userspage.exp_sel")}
                            </span>
                          }
                          filename="Users Data"
                        >
                          <ExcelSheet
                            data={JSON.parse(
                              localStorage.getItem("ManagementSelectedArray")
                            )}
                            name="Users"
                          >
                            <ExcelColumn label="ID" value="id" />
                            <ExcelColumn label="username" value="username" />
                            <ExcelColumn label="email" value="email" />
                            <ExcelColumn label="firstname" value="firstname" />
                            <ExcelColumn label="lastname" value="lastname" />
                            <ExcelColumn label="phone" value="phone" />
                            <ExcelColumn label="country" value="country" />
                            <ExcelColumn label="state" value="state" />
                            <ExcelColumn label="city" value="city" />
                            <ExcelColumn label="address" value="address" />
                            <ExcelColumn
                              label="postal_code"
                              value="postal_code"
                            />
                            <ExcelColumn
                              label="fiscal_code"
                              value="fiscal_code"
                            />
                            <ExcelColumn label="role" value="role" />
                            <ExcelColumn label="soc" value="soc" />
                            <ExcelColumn label="ling" value="ling" />
                            <ExcelColumn label="piva" value="piva" />
                            <ExcelColumn label="disable" value="disable" />
                            <ExcelColumn
                              label="date_added"
                              value="date_added"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </DropdownItem>
                      <DropdownItem onClick={this.handleExportSubmit}>
                        <ExcelFile
                          element={
                            <span className="react-bs-table-csv-btn btn btn-default">
                              {t("userspage.exp_all")}
                            </span>
                          }
                          filename="Users Data"
                        >
                          <ExcelSheet data={this.state.users} name="Users">
                            <ExcelColumn label="ID" value="id" />
                            <ExcelColumn label="username" value="username" />
                            <ExcelColumn label="email" value="email" />
                            <ExcelColumn label="firstname" value="firstname" />
                            <ExcelColumn label="lastname" value="lastname" />
                            <ExcelColumn label="phone" value="phone" />
                            <ExcelColumn label="country" value="country" />
                            <ExcelColumn label="state" value="state" />
                            <ExcelColumn label="city" value="city" />
                            <ExcelColumn label="address" value="address" />
                            <ExcelColumn
                              label="postal_code"
                              value="postal_code"
                            />
                            <ExcelColumn
                              label="fiscal_code"
                              value="fiscal_code"
                            />
                            <ExcelColumn label="role" value="role" />
                            <ExcelColumn label="soc" value="soc" />
                            <ExcelColumn label="ling" value="ling" />
                            <ExcelColumn label="piva" value="piva" />
                            <ExcelColumn label="disable" value="disable" />
                            <ExcelColumn
                              label="date_added"
                              value="date_added"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>

                {/* <li><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></li>
                        <li><CSVLink className="react-bs-table-csv-btn btn btn-default" data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></li> */}
              </ul>
              <br />
              <br />
              <hr />
              <BootstrapTable
                striped
                hover
                {...props.baseProps}
                selectRow={selectRow}
                filter={filterFactory()}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );

    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>Users | Nexion Engineering Web Portal</title>
            <meta
              name="description"
              content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background="#016362"
            color={"#ffffff"}
          />
          {/* <br/>
         <br/> */}
          <h4 className="text-center">{t("userspage.user_rec")}</h4>

          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
          <br />
          <br />
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(Users);
