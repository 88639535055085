import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import { MetaTags } from "react-meta-tags";
import "../../assets/scss/custom.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

import "chartist/dist/scss/chartist.scss";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";

class AutoDiagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tickets_visibility: true,
    };
    this.state = { value: localStorage.getItem("lang") };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }
  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(user_id);

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.users === 0) {
          window.history.back();
        }
        if (findresponses.tickets === 1) {
          this.setState({
            tickets_visibility: true,
          });
        } else {
          this.setState({
            tickets_visibility: false,
          });
        }
      });

    this.setState({
      logged_user: user_id,
      logged_username: user_name,
      loading: false,
    });
  }

  render() {
    const { t } = this.props;
    console.log("Logs T ");
    console.log(t);

    return (
      <React.Fragment>
        <MetaTags>
          <title>Auto Diagnosis | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Nexion Engineering"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="container-fluid">
          <Row className="homepageFirstRow">
            <Col xl={4}>
              <Card>
                <Link to="/dashboard" className="home_link">
                  <CardBody className="text-center homeIconCardBody">
                    <i class="ion ion-ios-speedometer text-nexion text-center homeIcon"></i>
                    <p>
                      <span>{t("menus.dashboard")}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <Link to="/diagnosis_list" className="home_link">
                  <CardBody className="text-center homeIconCardBody">
                    <b class="ion ion-ios-medkit text-nexion text-center homeIcon"></b>
                    <p>
                      <span>{t("menus.diagnosis")}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <Link to="/versions_list" className="home_link">
                  <CardBody className="text-center homeIconCardBody">
                    <b class="ion ion-ios-list text-nexion text-center homeIcon"></b>
                    <p>
                      <span>{t("menus.versions")}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>
          </Row>

          <Row>
            {this.state.tickets_visibility === true ? (
               <Col xl={4}>
               <Card>
                 <Link to="/tickets_list" className="home_link">
                   <CardBody className="text-center homeIconCardBody">
                     <b class="mdi mdi-jira text-nexion text-center jira_icon"></b>
                     <p>
                       <span>{t("menus.tickets_menu")}</span>
                     </p>
                   </CardBody>
                 </Link>
               </Card>
             </Col>
            ) : (
              ""
            )}
        <Col xl={4}>
        <Card>
          <Link to="/parametri" className="home_link">
            <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-cog text-nexion text-center homeIcon"></b>
              <p>
                <span>Parametri</span>
              </p>
            </CardBody>
          </Link>
        </Card>
      </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(AutoDiagnosis);
