import React from 'react';
import '../../assets/scss/custom.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from 'moment';
import {MetaTags} from 'react-meta-tags';
import $ from 'jquery';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Sugar} from 'react-preloaders';
import config from './../../helpers/Constants';

// const strumselectOptions = {
//     'Div.0': 'DIV.0'
// };
const checkselectOptions = {
    '1': 'OK',
    '0': 'NOK'
};
const socselectOptions = {
  'MM': 'Magneti Marelli',
  'TM': 'Tecnomotor'
};
const lingselectOptions = {
    'it-IT': 'Italian',
    'alb': 'Albanian',
    'ara': 'Arabic',
    'bra': 'Brazilian',
    'bul': 'Bulgarian',
    'cec': 'Czech',
    'cin': 'Chinese',
    'cro': 'Croatian',
    'fin': 'Finnish',
    'fra': 'French',
    'gre': 'Greek',
    'en-US': 'English',
    'jap': 'Japanese',
    'nor': 'Norwegian',
    'ola': 'Dutch',
    'pol': 'Polish',
    'por': 'Portuguese',
    'rum': 'Romanian',
    'rus': 'Russian',
    'ser': 'Serbian',
    'spa': 'Spanish',
    'ted': 'German',
    'tur': 'Turkish',
    'ung': 'Hungarian'
};
const { ExportCSVButton } = CSVExport;

const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

  
class SecurityGateway extends React.Component {
    constructor(props) {
        super(props);
        //this.state = {};
        this.state = {
          //value: localStorage.getItem('lang'),
          products: [],
          visible: false,
          modal_standard: false,
          default_date: new Date(),
          default: false,
          start_date: new Date(),
          end_date: new Date(),
          date: new Date(),
          export_btn:false,
          loading:true
        };
        
        this.tog_standard = this.tog_standard.bind(this);
        i18next.changeLanguage(localStorage.getItem('lang'));
           // DatePicker
        this.handleDefault = this.handleDefault.bind(this);
        this.handleAutoClose = this.handleAutoClose.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleEnd = this.handleEnd.bind(this);
        this.getFilteredDateData = this.getFilteredDateData.bind(this);
      }
      

      //DatePicker
      handleDefault(date) {
        this.setState({ default_date: date });
      }
      handleAutoClose(date) {
        this.setState({ auto_close: date });
      }
    
      handleStart(date) {
        this.setState({ start_date: date });
      }
      
      handleEnd(date) {
        this.setState({ end_date: date });
      }
      
      tog_standard() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();
      }
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }
      
      show() {
        this.setState({ visible: true });
      }
      hide() {
        this.setState({ visible: false });
      }

  componentDidMount() {

    $('.vertical-center i').on('click',function(){
      var pwidth = 400;

      if($(this).hasClass('left')){
        $('.react-bootstrap-table').scrollLeft( $('.react-bootstrap-table').scrollLeft()-pwidth );
      } else {
          $('.react-bootstrap-table').scrollLeft($('.react-bootstrap-table').scrollLeft()+pwidth );
      };


    });
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    if(authUser.role === "Administrator" || authUser.role === "Diagnosis Manager" || authUser.role === "Administrator and Site Org" || authUser.role === "Commercial"){
      this.setState({
        export_btn: true
      })
      
    }
    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.ac_version === 0){
        window.history.back();
      }
    })
    const data_visible_days = localStorage.getItem('data_visible_days');
    //const selected_product = localStorage.getItem('sel_product');
    
    //var days = "14";
    if(!data_visible_days){
      var days = "14";
    }else{
       days = data_visible_days;
    }
    // if(!selected_product){
    //   var select_product = "all";
    // }else{
    //   select_product = selected_product;
    // }

    var sgdata = [
        {
            "id": 17988,
            "serial_number": "190751538",
            "device_name": "SGW key",
            "ver_sw": "2.2",
            "vin": "ZFA26300006L97810",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-20 17:50:35",
            "utente": "W75216B",
            "file": 1,
            "logistic_mode": 0,
            "unlock": 0,
            "error": "02=Errore Server FCA, Username\Password non valide",
            "date_added": "2020-10-20 17:50:35"
        },
        {
            "id": 17986,
            "serial_number": "190751557",
            "device_name": "Security Pass",
            "ver_sw": "2.2",
            "vin": "ZFA3340000P736543",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-19 17:50:35",
            "utente": "W95155C",
            "file": 1,
            "logistic_mode": 0,
            "unlock": 0,
            "error": "02=Errore Server FCA, Username\Password non valide",
            "date_added": "2020-10-19 17:50:35"
        },
        {
            "id": 17984,
            "serial_number": "190751557",
            "device_name": "Security Pass",
            "ver_sw": "2.2",
            "vin": "ZFA3340000P736543",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-18 17:50:35",
            "utente": "W95155C",
            "file": 1,
            "logistic_mode": 0,
            "unlock": 0,
            "error": "  Centralina SGW non rilevata, sblocco non necessario. Diagnosi abilitata.",
            "date_added": "2020-10-18 17:50:35"
        }
    ]
    this.setState({
        products:sgdata,
        loading:false
    })
  }
  
   getFilteredDateData() {
    // const starting_date = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
    // const ending_date =moment(this.state.end_date).format('YYYY-MM-DD HH:mm:ss');
  
    // fetch(config.API+'ac_versions?starting_date='+starting_date+'&ending_date='+ending_date, {
    //   headers: {  
    //     'Nexion_API_Key': config.API_KEY
    //   }
    // }).then((Response)=>Response.json()).then((findresponse)=>{
    //   //console.log(findresponse)
    //   this.setState({
    //     products:findresponse,
    //   })
    // })
    var sgdata = [
        {
            "id": 17988,
            "serial_number": "190751538",
            "device_name": "SGW key",
            "ver_sw": "2.2",
            "vin": "ZFA26300006L97810",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-20 17:50:35",
            "utente": "W75216B",
            "file": 1,
            "logistic_mode": 0,
            "unlock": 0,
            "error": "02=Errore Server FCA, Username\Password non valide",
            "date_added": "2020-10-20 17:50:35"
        },
        {
            "id": 17986,
            "serial_number": "190751557",
            "device_name": "Security Pass",
            "ver_sw": "2.2",
            "vin": "ZFA3340000P736543",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-19 17:50:35",
            "utente": "W95155C",
            "file": 1,
            "logistic_mode": 0,
            "unlock": 0,
            "error": "02=Errore Server FCA, Username\Password non valide",
            "date_added": "2020-10-19 17:50:35"
        },
        {
            "id": 17984,
            "serial_number": "190751557",
            "device_name": "Security Pass",
            "ver_sw": "2.2",
            "vin": "ZFA3340000P736543",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-18 17:50:35",
            "utente": "W95155C",
            "file": 1,
            "logistic_mode": 0,
            "unlock": 0,
            "error": "  Centralina SGW non rilevata, sblocco non necessario. Diagnosi abilitata.",
            "date_added": "2020-10-18 17:50:35"
        }
    ]
    this.setState({
        products:sgdata
    })
    this.tog_standard();
  }
  resetpage() {
    window.location.reload(false);
  }

  render() {
   
    const { t } = this.props;
    const columns = [
      {
          text: "ID Secure",
          dataField: "id",
          filter: textFilter()
        },
        {
          text: t('versionpage.ser_no'),
          dataField: "serial_number",
          filter: textFilter(),
          formatter: (rowContent, row) => {
              const sn_no = row.serial_number;
              const id = row.id;
              return (    
                  <Link to={"/security_gateway_details?id="+id+"&serial_no="+sn_no}><button className='btn btn-outline-nexion btn lg'>{row.serial_number}</button></Link>
              )
            }
        },
        {
          text: t('versionpage.strum'),
          dataField: "device_name",
          filter: textFilter()
        },
        {
          text: "Ver SW",
          dataField: "ver_sw",
          filter: textFilter()
        },
        {
          text: "VIN",
          dataField: "vin",
          filter: textFilter()
        },
        {
            text: t('versionpage.ling'),
            dataField: "language",
            filter: selectFilter({
                options: lingselectOptions
            }),
        },
        {
            text: t('versionpage.soc'),
            dataField: "company",
            filter: selectFilter({
                options: socselectOptions
            }),
        },
        {
          
          text: "Data Diagnosi",
          dataField: "update_date",
          filter: dateFilter()
        },
        {
          text: "UTENTE",
          dataField: "utente",
          filter: textFilter()
        },
        {
          text: "file",
          dataField: "file",
          filter: selectFilter({
            options: checkselectOptions
            }),
            formatter: (cellContent, row) => {
                if (row.file) {
                return (
                    <i className="fa fa-check result_success_icons"></i>
                );
            }else{
                return (
                    <i className="fa fa-times result_failed_icons"></i>
                );
            }
            }
        },
        {
            text: "Logistic Mode",
            dataField: "logistic_mode",
            filter: selectFilter({
              options: checkselectOptions
              }),
              formatter: (cellContent, row) => {
                  if (row.logistic_mode) {
                  return (
                    <i className="fa fa-check result_success_icons"></i>
                  );
              }else{
                  return (
                    <i className="fa fa-times result_failed_icons"></i>
                  );
              }
              }
          },
          {
            text: "Unlock",
            dataField: "unlock",
            filter: selectFilter({
              options: checkselectOptions
              }),
              formatter: (cellContent, row) => {
                  if (row.unlock) {
                  return (
                    <i className="fa fa-check result_success_icons"></i>
                  );
              }else{
                  return (
                    <i className="fa fa-times result_failed_icons"></i>
                  );
              }
              }
          },
          {
            text: "Errore",
            dataField: "error",
            filter: textFilter()
          }
  ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('versionpage.firstPageText'),
      prePageText: t('versionpage.prePageText'),
      nextPageText: t('versionpage.nextPageText'),
      lastPageText: t('versionpage.lastPageText'),
      nextPageTitle: t('versionpage.nextPageTitle'),
      prePageTitle: t('versionpage.prePageTitle'),
      firstPageTitle: t('versionpage.firstPageTitle'),
      lastPageTitle: t('versionpage.lastPageTitle'),
      showTotal: true,
      totalSize: this.state.products.length,
      sizePerPageList: [
        {
          text: '10', value: 10
        }, 
        {
          text: '50', value: 50
        },{
          text: '100', value: 100
        }
      ]
    };
    
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="container-fluid">
          <ToolkitProvider
            keyField="id"
            data={ this.state.products }
            columns={ columns }
            exportCSV={ {  exportAll: false , onlyExportSelection: true, fileName: 'Security Gateway Data '+this.state.date+'.csv',} }
            >
            {
                props => (
                <div>
                    <ul className="export_btns">
                    <li><button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn filter_btn waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="ti-filter"></i>
                        </button></li>
                      {this.state.export_btn === true ? 
                      <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({ btnprimary1: !this.state.btnprimary1 })
                        }
                      >
                      <DropdownToggle tag="button" className="btn btn-success">
                      {t('versionpage.export')}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></DropdownItem>
                        <DropdownItem><CSVLink className="react-bs-table-csv-btn btn btn-default" filename={"Security Gateway Data "+this.state.date+".csv"} data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                      </li> : ""
                    }
                      
                        {/* <li><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></li>
                        <li><CSVLink className="react-bs-table-csv-btn btn btn-default" data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></li> */}
                    </ul>
                        <br/>
                        <br/>
                        <hr/>
                        <div className="table-wrapper">
                    <div className="vertical-center"><i class="ion ion-ios-arrow-dropleft-circle left"></i><i class="ion ion-ios-arrow-dropright-circle right"></i></div>
                    <BootstrapTable
                    striped
                    hover
                    { ...props.baseProps }
                    selectRow={ selectRow }
                    filter={ filterFactory() }
                    { ...paginationTableProps }
                    pagination={ paginationFactory(options) }
                    wrapperClasses="table-responsive"
                    />
                    </div>
                </div>
                )
            }
            </ToolkitProvider>
      </div>
    );

    return (
      
      <Card className="homepageFirstRow">
          <CardBody>
          <MetaTags>
      <title>Security Gateway | Nexion Engineering Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#016362" color={'#ffffff'} />
          {/* <br/>
         <br/> */}
          {/* <h4 className="text-center">{t('versionpage.version_rec')}</h4> */}

                      <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                          {t('versionpage.get_ver_data')}
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          
                          <Form action="#">
                              <FormGroup className="mb-0 forminput">
                             
                              <Row>
                                <Col md={12}>
                                  <label>{t('versionpage.start_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.start_date}
                                    selectsStart
                                    id="date_pick"
                                    className="date_pick form-control"
                                    placeholderText="From"
                                    name="start_dates"
                                    value={this.state.start_date} 
                                    // onChange={this.handleEmailChange}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleStart}
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col md={12}>
                                <label>{t('versionpage.end_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.end_date}
                                    selectsEnd
                                    id="date_pick"
                                    className="form-control"
                                    name="end_dates"
                                    value={this.state.end_date}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleEnd}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        </div>
                        <div className="modal-footer">
                        <button
                            type="button"
                            onClick={this.resetpage}
                            className="btn btn-warning waves-effect"
                            data-dismiss="modal"
                          >
                            {t('versionpage.reset_filter')}
                          </button>
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            {t('versionpage.cancel')}
                          </button>
                          <button
                            type="button"
                            onClick={this.getFilteredDateData}
                            className="btn filter_btn waves-effect waves-light"
                          >
                            {t('versionpage.proceed')}
                          </button>
                        </div>
                      </Modal>
                    
        <div className="container-fluid homepageFirstRow">
                         
          <Row>
            <Col xl="12">
            <Card>
                <CardBody>
                <Row>
                <div className="operations_div text-center">
                <h4 className="operations_div text-center">Security Gateway</h4>
                </div>
                <PaginationProvider
                    pagination={
                        paginationFactory(options)
                    }
                    >
                    { contentTable }
                  </PaginationProvider>
                </Row>
                </CardBody>
            </Card>
            </Col>
          </Row>
        </div>
        <br/>
        <br/>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(SecurityGateway);