import React from "react";
import classes from "./ToggleSwitch.module.css";

function ToggleSwitch(props) {
    const {
        text,
        checked,
        disabled,
        onChange,
        offstyle = "btn-danger",
        onstyle = "btn-success"
    } = props;

    let displayStyle = checked ? onstyle : offstyle;
    return (
        <>
            <label className={classes.toggleLabel}>
                <span className={classes.switch_wrapper}>
                    <input
                        type="checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={e => onChange(e)}
                    />
                    <span className={[classes.switch, displayStyle].join(" ")}>
                        <span className={classes.switch_handle} />
                    </span>
                </span>
                <span className={classes.switch_label}>{text}</span>
            </label>
        </>
    );
}

export default ToggleSwitch;
