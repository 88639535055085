import React, { Component } from "react";
import { Row, Col, Card, CardBody, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Cards from "react-bootstrap/Card";
//import ECU from '../../../src/assets/images/ecus/PCM.svg';
import ECU_White from "../../../src/assets/images/ecu_white.png";
//import ECU_Green from '../../../src/assets/images/ecu_green.png';
import queryString from "query-string";
import moment from "moment";
//import Modal from 'react-bootstrap/Modal'
import Table from "react-bootstrap/Table";
import { MetaTags } from "react-meta-tags";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
//import {Cube} from 'react-preloaders';
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";

class DiagnosisDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosis: [],
      version: [],
      location: [],
      vehicle: [],
      vehicle_status: [],
      global_clear_ecu: [],
      battery_info: [],
      single_ecu_operation: [],
      security_gateway_unlock: [],
      modal_standard: false,
      dtc: [],
      d_date: "",
      continent_code: "",
      continent_name: "",
      country_code: "",
      country_name: "",
      ip_address: "",
      state_prov: "",
      city: "",
      loading: true,
    };

    this.tog_standard = this.tog_standard.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.getDtc = this.getDtc.bind(this);
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  tog_standard(loc) {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  // getReport(id) {
  //   alert('hii');
  // }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });
    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const diag_id = values.diagnosis_id;
    fetch(config.API + "diagnosis/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // var d_date = findresponse.diagnosis.diagnosis_date.
        this.setState({
          diagnosis: findresponse.diagnosis,
          location: findresponse.location,
          vehicle: findresponse.selected_vehicle,
          version: findresponse.versions,
          d_date: findresponse.diagnosis.diagnosis_date
            .replace(/T/, " ")
            .replace(/\..+/, ""),
        });
        if (findresponse.location !== undefined) {
          this.setState({
            continent_code: findresponse.location.continent_code,
            continent_name: findresponse.location.continent_name,
            country_code: findresponse.location.country_code,
            country_name: findresponse.location.country_name,
            city: findresponse.location.city,
            state_prov: findresponse.location.state_prov,
            ip_address: findresponse.location.ip_address,
          });
        }
      });

    fetch(config.API + "diagnosis_bus_list/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          vehicle_status: findresponse.vehicle_status.bus,
        });
      });

    fetch(config.API + "diagnosis_seo_list/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          single_ecu_operation: findresponse.single_ecu_operation,
        });
      });

    fetch(config.API + "diagnosis_gce_list/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          global_clear_ecu: findresponse.global_clear_ecu,
          loading: false,
        });
      });

    fetch(config.API + "diagnosis_sgu_list/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          security_gateway_unlock: findresponse.security_gateway_unlock,
        });
      });
  }

  getDtc(id) {
    var ecu_id = id.target.value;
    //alert("modal" + loc_id);
    fetch(config.API + "diagnosis_ecu_dtc/" + ecu_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse)
        this.setState({
          dtc: findresponse,
        });
      });
    this.tog_standard();
    //alert(loc_id);
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Diagnosis Details | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="container-fluid homepageFirstRow">
          <Row className="diag_info_div">
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.diag_id")}:</b>{" "}
                    {this.state.diagnosis.id}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.serial")}:</b>{" "}
                    {this.state.diagnosis.serial_number}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.device_name")}:</b>{" "}
                    {this.state.diagnosis.device_name}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.lang")}:</b>{" "}
                    {this.state.diagnosis.language}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="diag_info_div">
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.company")}:</b>{" "}
                    {this.state.diagnosis.company}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.market")}:</b>{" "}
                    {this.state.diagnosis.market}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.pass_exp")}:</b>
                    <span className="pass_text">
                      {" "}
                      {this.state.diagnosis.password}
                    </span>
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <p>
                    <b>{t("diagnosisdetailspage.diagnosis_date")}:</b>{" "}
                    {this.state.d_date}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    {t("diagnosisdetailspage.version_details")}
                  </h4>

                  <div className="table-responsive">
                    <table className="table table-sm m-0">
                      <thead>
                        <tr>
                          <th>{t("diagnosisdetailspage.version")}</th>
                          <th>{t("diagnosisdetailspage.value")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Software</td>
                          <td>{this.state.version.software}</td>
                        </tr>

                        <tr>
                          <td>Vehicle Selection DB</td>
                          <td>{this.state.version.vehicle_database}</td>
                        </tr>
                        <tr>
                          <td>Vehicle DB</td>
                          <td>{this.state.version.vehicle_properties}</td>
                        </tr>
                        <tr>
                          <td>Libraries</td>
                          <td>{this.state.version.library}</td>
                        </tr>
                        <tr>
                          <td>Framework</td>
                          <td>{this.state.version.nxevci}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.ok_func")}</td>
                          <td>{this.state.version.ok_function_database}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.plants_db")}</td>
                          <td>{this.state.version.plants_database}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.lang_db")}</td>
                          <td>{this.state.version.language_database}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.auto_db")}</td>
                          <td>{this.state.version.autodiagnosis}</td>
                        </tr>
                        <tr>
                          <td>SW Update</td>
                          <td>{this.state.version.sw_update}</td>
                        </tr>
                        <tr>
                          <td>Scripts</td>
                          <td>{this.state.version.scripts}</td>
                        </tr>
                        <tr>
                          <td>Kernel</td>
                          <td>{this.state.version.kernel}</td>
                        </tr>
                        <tr>
                          <td>Firmware</td>
                          <td>{this.state.version.firmware}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    {t("diagnosisdetailspage.sel_vehicle")}
                  </h4>
                  {this.state.vehicle.brand !== "" ? (
                    <img
                      src={`../brand_logos/${this.state.vehicle.brand}.png`}
                      alt={this.state.vehicle.brand}
                      className="brand_img"
                    />
                  ) : (
                    ""
                  )}

                  <div className="table-responsive">
                    <table className="table table-sm m-0">
                      <thead>
                        <tr>
                          <th>{t("diagnosisdetailspage.property")}</th>
                          <th>{t("diagnosisdetailspage.value")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("diagnosisdetailspage.brand")}</td>
                          <td>{this.state.vehicle.brand}</td>
                        </tr>

                        <tr>
                          <td>{t("diagnosisdetailspage.family")}</td>
                          <td>{this.state.vehicle.family}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.chassis")}</td>
                          <td>{this.state.vehicle.chassis}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.chassis_year")}</td>
                          <td>
                            {this.state.vehicle.chassis_year_from} -{" "}
                            {this.state.vehicle.chassis_year_to}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.var_name")}</td>
                          <td>{this.state.vehicle.variant_name}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.var_year")}</td>
                          <td>
                            {this.state.vehicle.variant_year_from} -{" "}
                            {this.state.vehicle.variant_year_to}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.fuel")}</td>
                          <td>
                            {this.state.vehicle.fuel === "D"
                              ? "Diesel"
                              : "Petrol"}
                          </td>
                        </tr>

                        <tr>
                          <td>{t("diagnosisdetailspage.eng_code")}</td>
                          <td>{this.state.vehicle.engine_code_clear}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.disp")}</td>
                          <td>{this.state.vehicle.displacement}</td>
                        </tr>
                        <tr>
                          <td>Kw</td>
                          <td>{this.state.vehicle.kw}</td>
                        </tr>
                        <tr>
                          <td>Typeidk</td>
                          <td>{this.state.vehicle.typeidk}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.veh_prop_id")}</td>
                          <td>{this.state.vehicle.vehicle_properties_id}</td>
                        </tr>
                        <tr>
                          <td>VIN</td>
                          <td>{this.state.vehicle.vin}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.plate_number")}</td>
                          <td>{this.state.vehicle.plate_number}</td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.first_reg")}</td>
                          <td>
                            {this.state.vehicle.first_registration !== ""
                              ? moment(
                                  this.state.vehicle.first_registration
                                ).format("YYYY-MM-DD")
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.last_car_rev")}</td>
                          <td>
                            {this.state.vehicle.last_car_review !== ""
                              ? moment(
                                  this.state.vehicle.last_car_review
                                ).format("YYYY-MM-DD")
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.next_car_rev")}</td>
                          <td>
                            {this.state.vehicle.next_car_review !== ""
                              ? moment(
                                  this.state.vehicle.next_car_review
                                ).format("YYYY-MM-DD")
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title">
                    {t("diagnosisdetailspage.loc_details")}
                  </h4>

                  <div className="table-responsive">
                    <table className="table m-0">
                      <thead>
                        <tr>
                          <th>{t("diagnosisdetailspage.loc_type")}</th>
                          <th>{t("diagnosisdetailspage.value")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("diagnosisdetailspage.ip_add")}</td>
                          <td>
                            {this.state.ip_address !== undefined &&
                            this.state.ip_address !== ""
                              ? this.state.ip_address
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <td>{t("diagnosisdetailspage.cont_code")}</td>
                          <td>
                            {this.state.continent_code !== undefined &&
                            this.state.continent_code !== ""
                              ? this.state.continent_code
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.cont_name")}</td>
                          <td>
                            {this.state.continent_name !== undefined &&
                            this.state.continent_name !== ""
                              ? this.state.continent_name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.con_code")}</td>
                          <td>
                            {this.state.country_code !== undefined &&
                            this.state.country_code !== ""
                              ? this.state.country_code
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.con_name")}</td>
                          <td>
                            {this.state.country_name !== undefined &&
                            this.state.country_name !== ""
                              ? this.state.country_name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.state")}</td>
                          <td>
                            {this.state.state_prov !== undefined &&
                            this.state.state_prov !== ""
                              ? this.state.state_prov
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("diagnosisdetailspage.city")}</td>
                          <td>
                            {this.state.city !== undefined &&
                            this.state.city !== ""
                              ? this.state.city
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br />
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  <h5 className="mt-0 mb-3">
                    {t("diagnosisdetailspage.vehicle_status")}
                  </h5>
                </Col>
                {/* <Col lg={10}>
    <span className="battery_class">Battery: {this.state.battery_info.battery} <i className="ion ion-md-battery-charging"></i></span>
                </Col> */}
              </Row>

              <Card>
                <CardBody>
                  <h5 className="mt-0 mb-3"># BUS</h5>
                  <div id="cd-timeline">
                    <ul className="timeline list-unstyled">
                      {/* {this.state.diagnosis_info.map((bus, key) => */}
                      {this.state.vehicle_status.map((bus, index) => {
                        return (
                          <li className="timeline-list">
                            <div className="cd-timeline-content">
                              <h6 className="mt-0 mb-3">
                                {t("diagnosisdetailspage.list")}
                              </h6>
                              <Row>
                                {bus.ecus.map((ecu, indexa) => {
                                  return ecu.status === "ok" ||
                                    ecu.status === "COMM_NO_DTC" ? (
                                    <Col lg={2}>
                                      {ecu.dtc && ecu.dtc.length > 0 ? (
                                        <Card className="ecu_card card-dtc">
                                          <CardBody className="ecu-card text-center">
                                            {ecu.e_group !== "" ? (
                                              <img
                                                src={`../ecus/${ecu.e_group}.svg`}
                                                alt="ecu"
                                                className="ecu_img"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <p className="ecu-text mb-0">
                                              {ecu.e_group}
                                            </p>
                                            <p className="ecu-name-text">
                                              {ecu.name}
                                            </p>
                                            {ecu.dtc && ecu.dtc.length > 0 ? (
                                              <button
                                                className="btn dtc_btn btn-dtc waves-effect waves-light"
                                                onClick={
                                                  this.tog_standard &&
                                                  this.getDtc
                                                }
                                                data-toggle="modal"
                                                value={ecu.id}
                                                data-target="#myModal"
                                              >
                                                DTC
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </CardBody>
                                        </Card>
                                      ) : (
                                        <Card className="ecu_card card-success">
                                          <CardBody className="ecu-card text-center">
                                            {ecu.e_group !== "" ? (
                                              <img
                                                src={`../ecus/${ecu.e_group}.svg`}
                                                alt="ecu"
                                                className="ecu_img"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <p className="ecu-text mb-0">
                                              {ecu.e_group}
                                            </p>
                                            <p className="ecu-name-text">
                                              {ecu.name}
                                            </p>
                                            {ecu.dtc && ecu.dtc.length > 0 ? (
                                              <button
                                                className="btn dtc_btn btn-dtc waves-effect waves-light"
                                                onClick={
                                                  this.tog_standard &&
                                                  this.getDtc
                                                }
                                                data-toggle="modal"
                                                value={ecu.id}
                                                data-target="#myModal"
                                              >
                                                DTC
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </CardBody>
                                        </Card>
                                      )}
                                    </Col>
                                  ) : ecu.status === "DTC_NOT_SUPPORTED" ? (
                                    <Col lg={2}>
                                      {ecu.dtc && ecu.dtc.length > 0 ? (
                                        <Card className="ecu_card card-dtc">
                                          <CardBody className="ecu-card text-center">
                                            {ecu.e_group !== "" ? (
                                              <img
                                                src={`../ecus/${ecu.e_group}.svg`}
                                                alt="ecu"
                                                className="ecu_img"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <p className="ecu-text mb-0">
                                              {ecu.e_group}
                                            </p>
                                            <p className="ecu-name-text">
                                              {ecu.name}
                                            </p>
                                            {ecu.dtc && ecu.dtc.length > 0 ? (
                                              <button
                                                className="btn dtc_btn btn-dtc waves-effect waves-light"
                                                onClick={
                                                  this.tog_standard &&
                                                  this.getDtc
                                                }
                                                data-toggle="modal"
                                                value={ecu.id}
                                                data-target="#myModal"
                                              >
                                                DTC
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </CardBody>
                                        </Card>
                                      ) : (
                                        <Card className="ecu_card card-not-sup">
                                          <CardBody className="ecu-card text-center">
                                            {ecu.e_group !== "" ? (
                                              <img
                                                src={`../ecus/${ecu.e_group}.svg`}
                                                alt="ecu"
                                                className="ecu_img"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <p className="ecu-text mb-0">
                                              {ecu.e_group}
                                            </p>
                                            <p className="ecu-name-text">
                                              {ecu.name}
                                            </p>
                                            {ecu.dtc && ecu.dtc.length > 0 ? (
                                              <button
                                                className="btn dtc_btn btn-dtc waves-effect waves-light"
                                                onClick={
                                                  this.tog_standard &&
                                                  this.getDtc
                                                }
                                                data-toggle="modal"
                                                value={ecu.id}
                                                data-target="#myModal"
                                              >
                                                DTC
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </CardBody>
                                        </Card>
                                      )}
                                    </Col>
                                  ) : (
                                    <Col lg={2}>
                                      {" "}
                                      {ecu.dtc && ecu.dtc.length > 0 ? (
                                        <Card className="ecu_card card-dtc">
                                          <CardBody className="ecu-card text-center">
                                            {ecu.e_group !== "" ? (
                                              <img
                                                src={`../ecus/${ecu.e_group}.svg`}
                                                alt="ecu"
                                                className="ecu_img"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <p className="mb-0 ecu-text">
                                              {ecu.e_group}
                                            </p>
                                            <p className="ecu-name-text">
                                              {ecu.name}
                                            </p>
                                            {ecu.dtc && ecu.dtc.length > 0 ? (
                                              <button
                                                className="btn dtc_btn btn-dtc waves-effect waves-light"
                                                onClick={
                                                  this.tog_standard &&
                                                  this.getDtc
                                                }
                                                data-toggle="modal"
                                                value={ecu.id}
                                                data-target="#myModal"
                                              >
                                                DTC
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </CardBody>
                                        </Card>
                                      ) : (
                                        <Card className="ecu_card card-failed">
                                          <CardBody className="ecu-card text-center">
                                            {ecu.e_group !== "" ? (
                                              <img
                                                src={`../ecus/${ecu.e_group}.svg`}
                                                alt="ecu"
                                                className="ecu_img"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <p className="mb-0 ecu-text">
                                              {ecu.e_group}
                                            </p>
                                            <p className="ecu-name-text">
                                              {ecu.name}
                                            </p>
                                            {ecu.dtc && ecu.dtc.length > 0 ? (
                                              <button
                                                className="btn dtc_btn btn-dtc waves-effect waves-light"
                                                onClick={
                                                  this.tog_standard &&
                                                  this.getDtc
                                                }
                                                data-toggle="modal"
                                                value={ecu.id}
                                                data-target="#myModal"
                                              >
                                                DTC
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </CardBody>
                                        </Card>
                                      )}
                                    </Col>
                                  );
                                })}
                              </Row>

                              <div className="date date_red">
                                <h4 className="mt-0 timeline_h4">{bus.name}</h4>
                                {bus.type === "ISO_11898_2_DWCAN" ||
                                bus.type === "ISO_11898_3_DWFTCAN" ||
                                bus.type === "OK_FUNCTIONS" ? (
                                  <span>
                                    <h6>Type: CAN</h6>
                                    <p className="mb-0 bus-text">
                                      <b>Speed: </b> {bus.speed / 1000}K
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b>Pin High: </b> {bus.pin_high}
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b>Pin Low: </b> {bus.pin_low}
                                    </p>
                                  </span>
                                ) : bus.type === "EOBD" ? (
                                  <span></span>
                                ) : (
                                  <span>
                                    <h6>Type: K-Line</h6>
                                    <p className="mb-0 bus-text">
                                      <b>Speed: </b> {bus.speed / 1000}K
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b>Pin K: </b> {bus.pin_k}
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b>Pin L: </b> {bus.pin_l}
                                    </p>
                                  </span>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <h5 className="mt-0 mb-3"># GLOBAL CLEAR ECU</h5>

                  <div id="cd-timeline">
                    <ul className="timeline list-unstyled">
                      {/* {this.state.diagnosis_info.map((bus, key) => */}
                      {this.state.global_clear_ecu.map(function (bus, index) {
                        return (
                          <li className="timeline-list ">
                            <div className="cd-timeline-content">
                              <Row>
                                <Col lg={12}>
                                  <p>
                                    <b>
                                      {t("diagnosisdetailspage.date_time")}:{" "}
                                    </b>{" "}
                                    {moment(bus.datetime).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </p>
                                </Col>
                              </Row>
                              <h6 className="mt-0 mb-3">SUCCESS</h6>
                              <Accordion defaultActiveKey="0">
                                {bus.succes.map(function (ecu, indexa) {
                                  return (
                                    <Cards>
                                      <Cards.Header>
                                        <Accordion.Toggle
                                          as={Button}
                                          className="acc_btn"
                                          variant="link"
                                          eventKey={ecu.id}
                                        >
                                          {ecu.ecu_name}
                                        </Accordion.Toggle>
                                        {ecu.success === 1 ? (
                                          <button className="btn btn-success waves-effect waves-light btn btn-success gce_status_btn">
                                            {t("diagnosisdetailspage.success")}
                                          </button>
                                        ) : (
                                          <button className="btn btn-danger waves-effect waves-light btn btn-success gce_status_btn">
                                            {t("diagnosisdetailspage.failed")}
                                          </button>
                                        )}
                                      </Cards.Header>
                                      <Accordion.Collapse eventKey={ecu.id}>
                                        <Cards.Body>
                                          <Table responsive>
                                            <thead>
                                              <tr>
                                                <th>ID</th>
                                                <th>ECU Name</th>
                                                <th>Group</th>
                                                <th>success</th>
                                                <th>
                                                  {t(
                                                    "diagnosisdetailspage.date_added"
                                                  )}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>{ecu.id}</td>
                                                <td>{ecu.ecu_name}</td>
                                                <td>{ecu.ecu_group}</td>
                                                <td>
                                                  {ecu.success === 1
                                                    ? "Success"
                                                    : "Failed"}
                                                </td>
                                                <td>
                                                  {moment(
                                                    ecu.date_added
                                                  ).format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Cards.Body>
                                      </Accordion.Collapse>
                                    </Cards>
                                  );
                                })}
                              </Accordion>
                              <div className="date date_green">
                                <h4 className="mt-0 timeline_h4">
                                  <i className="fas fa-car-battery"></i>
                                  <br />
                                  {bus.battery
                                    ? parseFloat(bus.battery).toFixed(1)
                                    : ""}{" "}
                                </h4>
                                <p className="mb-0 text-white-50">
                                  <i className="far fa-clock"></i>{" "}
                                  {moment(bus.datetime).format("LL")}
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5 className="mt-0 mb-3"># SINGLE ECU OPERATION</h5>
                  <div id="cd-timeline">
                    <ul className="timeline list-unstyled">
                      {/* {this.state.diagnosis_info.map((bus, key) => */}
                      {this.state.single_ecu_operation.map(function (
                        bus,
                        index
                      ) {
                        return (
                          <li className="timeline-list">
                            <div className="cd-timeline-content">
                              {/* <p className ="float_right"><b>{t('diagnosisdetailspage.date_added')}: </b> {moment(bus.date_added).format('YYYY-MM-DD HH:mm:ss')}</p> */}
                              <br />
                              {bus.diagnosis_info.length !== 0 ? (
                                <div>
                                  <h5 className="mt-0 mb-3">
                                    {t("diagnosisdetailspage.diag_info")}
                                  </h5>
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>
                                          ID {t("diagnosisdetailspage.text")}
                                        </th>
                                        <th>
                                          ID {t("diagnosisdetailspage.value")}
                                        </th>
                                        <th>
                                          {t("diagnosisdetailspage.desc")}
                                        </th>
                                        <th>
                                          {t("diagnosisdetailspage.value")}
                                        </th>
                                        {/* <th>{t('diagnosisdetailspage.date_added')}</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bus.diagnosis_info.map(function (
                                        di,
                                        indexc
                                      ) {
                                        return (
                                          <tr>
                                            <td>{di.id}</td>
                                            <td>{di.idtext}</td>
                                            <td>{di.idvalue}</td>
                                            <td>{di.text}</td>
                                            <td>{di.value}</td>
                                            {/* <td>{moment(di.date_added).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                ""
                              )}
                              <br />
                              {bus.active_diagnosis.length !== 0 ? (
                                <div>
                                  <h5 className="mt-0 mb-3">
                                    {t("diagnosisdetailspage.act_diag")}
                                  </h5>
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>
                                          {t("diagnosisdetailspage.name")}
                                        </th>
                                        <th>
                                          {t("diagnosisdetailspage.type")}
                                        </th>
                                        {/* <th>{t('diagnosisdetailspage.date_time')}</th> */}
                                        <th>
                                          {t("diagnosisdetailspage.battery")}
                                        </th>
                                        <th>
                                          {t("diagnosisdetailspage.result")}
                                        </th>
                                        <th>
                                          {t("diagnosisdetailspage.date_added")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {bus.active_diagnosis.map(function (
                                        ad,
                                        indexc
                                      ) {
                                        return (
                                          <tr>
                                            <td>{ad.id}</td>
                                            <td>{ad.name}</td>
                                            <td>{ad.type}</td>
                                            {/* <td>{moment(ad.datetime).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                                            <td>{ad.battery}</td>
                                            <td>
                                              {ad.success === 1 ? (
                                                <button className="btn btn-success waves-effect waves-light btn btn-success">
                                                  {t(
                                                    "diagnosisdetailspage.success"
                                                  )}
                                                </button>
                                              ) : (
                                                <button className="btn btn-danger waves-effect waves-light bg-danger">
                                                  {t(
                                                    "diagnosisdetailspage.failed"
                                                  )}
                                                </button>
                                              )}
                                            </td>
                                            <td>
                                              {ad.datetime
                                                .replace(/T/, " ")
                                                .replace(/\..+/, "")}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="date date_red">
                                <h4 className="mt-0 timeline_h4">
                                  <img
                                    src={ECU_White}
                                    alt="ecu"
                                    className="ecu_img_small"
                                  />
                                  {bus.ecu}
                                </h4>
                                {/* <p className="mb-0 text-white-50"><i className="far fa-clock"></i> { moment(bus.date_added).format('LL')}</p> */}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  </CardBody>
                  </Card>
                  <Card>
                <CardBody>
                  <h5 className="mt-0 mb-3"># SECURITY GATEWAY UNLOCK</h5>
                  <div id="cd-timeline">
                    <ul className="timeline list-unstyled">
                      {/* {this.state.diagnosis_info.map((bus, key) => */}
                      {this.state.security_gateway_unlock.length !== 0 ? (
                          <li className="timeline-list">
                            <div className="cd-timeline-content">
                              {/* <p className ="float_right"><b>{t('diagnosisdetailspage.date_added')}: </b> {moment(bus.date_added).format('YYYY-MM-DD HH:mm:ss')}</p> */}
                              {/* <br /> */}
                             
                                <div>
                                  {/* <h5 className="mt-0 mb-3">
                                    Security Gateway Unlock Details
                                  </h5> */}
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th>Data e ora</th>
                                        <th>
                                        Credenziali
                                        </th>
                                        <th>Esito</th>
                                        <th>
                                        Tipo
                                        </th>
                                        <th>
                                        Workshop Id
                                        </th>
                                        <th>
                                        Stato licenza
                                        </th>
                                        <th>
                                        Scadenza licenza
                                        </th>
                                        <th>
                                        Tokens rimanenti
                                        </th>
                                        <th>
                                        Username
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.security_gateway_unlock.map(function (
                                      sgu,
                                      index
                                    ) {
                                      const credentials = sgu.credentials;
                                      if (credentials === 0) {
                                        var cred = "NONE";
                                      }else if (credentials === 1) {
                                        var cred = "FIREWALL";
                                      }else if (credentials === 2) {
                                        var cred = "SGM";
                                      }else if (credentials === 3) {
                                        var cred = "FCA";
                                      }

                                      const status = sgu.security_gateway_status;
                                      if (status === 0) {
                                        var sts = "Blocked";
                                      }else if (status === 1) {
                                        var sts = "Unlocked";
                                      }else if (status === 2) {
                                        var sts = "Already Unlocked";
                                      }

                                      const sgu_type = sgu.security_gateway_type;
                                      if (sgu_type === 1) {
                                        var sg_type = "FCA";
                                      }else if (sgu_type === 2) {
                                        var sg_type = "MERCEDES";
                                      }else if (sgu_type === 3) {
                                        var sg_type = "KIA";
                                      }else if (sgu_type === 4) {
                                        var sg_type = "RENAULT";
                                      }else if (sgu_type === 5) {
                                        var sg_type = "SUBARU";
                                      }else if (sgu_type === 6) {
                                        var sg_type = "VOLVO";
                                      }

                                      const licence_sts = sgu.licence_status;
                                      if (licence_sts === "0") {
                                        var lic_sts = "Expired";
                                      }else if(licence_sts === "1"){
                                        var lic_sts = "Active";
                                      }else{
                                        var lic_sts = "";
                                      }

                                      return (
                                        
                                          <tr>
                                            <td>{sgu.datetime}</td>
                                            <td>{cred}</td>
                                            <td>{sts}</td>
                                            <td>{sg_type}</td>
                                            <td>{sgu.workshop_id}</td>
                                            <td>{lic_sts}</td>
                                            <td>{sgu.licence_expire}</td>
                                            <td>{sgu.tokens}</td>
                                            <td>{sgu.username}</td>
                                          </tr>
                                          )})}
                                    </tbody>
                                  </Table>
                                </div>

                              <div className="date date_red">
                                  <span>
                                    <h6>VEHICLE</h6>
                                    <p className="mb-0 bus-text">
                                      <b className="sgu_vehicle_title">{t("diagnosisdetailspage.brand")}: </b> {this.state.vehicle.brand}
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b className="sgu_vehicle_title">{t("diagnosisdetailspage.family")}: </b> {this.state.vehicle.family}
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b className="sgu_vehicle_title">{t("diagnosisdetailspage.chassis")}: </b> {this.state.vehicle.chassis}
                                    </p>
                                    <p className="mb-0 bus-text">
                                      <b className="sgu_vehicle_title">Codice Motore: </b> {this.state.vehicle.engine_code_clear}
                                    </p>
                                  </span>
                              </div>
                            </div>
                          </li> ) : ("")}
                    </ul>
                  </div>

                  <Link
                    to={
                      "/diagnosis_report?diagnosis_id=" +
                      this.state.diagnosis.id
                    }
                  >
                    <button className="btn btn-mm waves-effect waves-light ">
                      <i className="ion ion-md-document"></i> Visualizza Report
                    </button>
                  </Link>
                  {this.state.diagnosis.trace_filename !== null ? (
                    <a
                      href={
                        "https://nexion-webportal-uploads.s3.eu-central-1.amazonaws.com/" +
                        this.state.diagnosis.trace_filename
                      }
                    >
                      <button className="btn btn-trace-log waves-effect waves-light ">
                        <i className="ion ion-md-document"></i> Trace Log
                      </button>
                    </a>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={this.state.modal_standard}
            toggle={this.tog_standard}
            className="modal-lg"
          >
            <div className="modal-body">
              <Card>
                <CardBody>
                  <h4 className="text-center">DTC</h4>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{t("diagnosisdetailspage.j2012")}</th>
                        <th>{t("diagnosisdetailspage.hex")}</th>
                        <th>{t("diagnosisdetailspage.status")}</th>
                        <th>{t("diagnosisdetailspage.desc")}</th>
                        <th>{t("diagnosisdetailspage.desc_index")}</th>
                        {/* <th>{t('diagnosisdetailspage.date_added')}</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dtc.map((dtc, i) => {
                        return (
                          <tr>
                            <td>{dtc.j2012}</td>
                            <td>{dtc.hex}</td>
                            <td>{dtc.status}</td>
                            <td>{dtc.description}</td>
                            <td>{dtc.description_index}</td>
                            {/* <td>{dtc.date_added.replace(/T/, ' ').replace(/\..+/, '')}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.tog_standard}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DiagnosisDetails);
