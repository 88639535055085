import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

//Account Redux states
import { ADD_SOFTWARE_VERSION } from './actionTypes'
import {
  addSoftwareVersionSuccessful,
  addSoftwareVersionFailed,
} from './actions'

//AUTH related methods
import { getFirebaseBackend } from '../../helpers/softwareVersionUtils'

const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
function* addSoftwareVersion({ payload: { softwareVersionData } }) {
  try {
    const response = yield call(
      fireBaseBackend.addSoftwareVersion,
      softwareVersionData
    )
    yield put(addSoftwareVersionSuccessful(response))
  } catch (error) {
    yield put(addSoftwareVersionFailed(error))
  }
}

export function* watchAddSoftwareVersion() {
  yield takeEvery(ADD_SOFTWARE_VERSION, addSoftwareVersion)
}

function* SoftwareVersionSaga() {
  yield all([fork(watchAddSoftwareVersion)])
}

export default SoftwareVersionSaga
