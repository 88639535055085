import firebase from 'firebase/app'
// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'
import axios from 'axios'
import config from './../helpers/Constants'

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user))
        }
        // else {
        //   sessionStorage.removeItem("authUser");
        // }
      })
    }
  }

  /**
   * Registers the user with given details
   */
  addSoftwareVersion = (softwareVersionData) => {
    let decodedString = localStorage.getItem('authUser')
    let authUser = JSON.parse(atob(decodedString))
    var user_name = authUser.username
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: user_name,
      },
    }
    //console.log(user);
    return new Promise((resolve, reject) => {
      axios
        .post(
          config.API + 'software_version?company=Nexion',
          softwareVersionData,
          options
        )
        .then(
          (softwareVersionDetails) => {
            if (softwareVersionDetails.data.status === 'success') {
              alert('Software Version Added Successfully')
              window.location.reload(false)
            } else if (softwareVersionDetails.data.status === 'failed') {
              alert('Software Versionname is Already Exists!!')
            } else {
              alert('There is some problem while adding software version')
            }
          },
          (error) => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
