import React from 'react';
import '../../assets/scss/custom.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
//import ToolkitProvider, { CSVExport, ColumnToggle  } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import queryString from 'query-string';
import {MetaTags} from 'react-meta-tags';
import moment from 'moment';
import {Sugar} from 'react-preloaders';
import config from '../../helpers/Constants';
import {
  Card,
  CardBody,
  Modal,
  Row,
  Col
} from "reactstrap";
//import { Link } from "react-router-dom";

const { ExportCSVButton } = CSVExport;

const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

class SecurityGatewayDetails extends React.Component {
    constructor(props) {
      
        super(props);
        //this.state = {};
        this.state = {
          value: localStorage.getItem('lang'),
          modal_standard: false,
          modal_standard_cus: false,
          loading:true,
          products: [],
          locations: [],
          customer:[],
          export_btn: false
        };
        i18next.changeLanguage(localStorage.getItem('lang'));
        this.tog_standard = this.tog_standard.bind(this);
        this.handleAutoClose = this.handleAutoClose.bind(this);
      }

      handleAutoClose(date) {
        this.setState({ auto_close: date });
      }

      tog_standard(loc) {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();
      }
    
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }
      
      show() {
        this.setState({ visible: true });
      }
      hide() {
        this.setState({ visible: false });
      }

  componentDidMount() {

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);
    if(authUser.role === "Administrator" || authUser.role === "Diagnosis Manager" || authUser.role === "Administrator and Site Org" || authUser.role === "Commercial"){
      this.setState({
        export_btn: true
      })
      
    }

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.ac_version === 0){
        window.history.back();
      }
    })
    
    const values = queryString.parse(this.props.location.search);
    const sr_n = values.serial_no;
    // fetch(config.API+'ac_versions/'+sr_n, {
    //   headers: {  
    //     'Nexion_API_Key': config.API_KEY
    //   }
    // }).then((Response)=>Response.json()).then((findresponse)=>{
      
    //   this.setState({
    //     products:findresponse,
    //     loading:false
    //   })
    // })
    //console.log(findresponse);
    var sgdata = [
        {
            "id": 17988,
            "serial_number": "190751538",
            "device_name": "SGW key",
            "ver_sw": "2.2",
            "fw": "1.2",
            "vin": "ZFA26300006L97810",
            "language": "it-IT",
            "company": "TM",
            "update_date": "2020-10-20 17:50:35",
            "utente": "W75216B",
            "file": "190751538_2020-10-20-175034_vin.trc",
            "logistic_mode": 0,
            "unlock": 0,
            "tester": 0,
            "windows_10": 1,
            "read_vin": 1,
            "error": "02=Errore Server FCA, Username\Password non valide",
            "date_added": "2020-10-20 17:50:35"
        }
    ]
    this.setState({
        products:sgdata,
        loading:false
    })
  }

  render() {
    const { t } = this.props;
    const columns = [
      {
        text: "Data Diagnosi",
        dataField: "update_date"
      },
      {
        text: t('versionpage.strum'),
        dataField: "device_name"
      },
      {
        text: "Ver SW",
        dataField: "ver_sw"
      },
      {
        text: "FW",
        dataField: "fw"
      },
      {
        text: "VIN",
        dataField: "vin"
      },
      {
        text: "file",
        dataField: "file",
      },
      {
        text: "Logistic Mode",
        dataField: "logistic_mode",
          formatter: (cellContent, row) => {
              if (row.logistic_mode) {
              return (
                <i className="fa fa-check result_success_icons"></i>
              );
          }else{
              return (
                <i className="fa fa-times result_failed_icons"></i>
              );
          }
          }
      },
      {
        text: "Unlock",
        dataField: "unlock",
          formatter: (cellContent, row) => {
              if (row.unlock) {
              return (
                <i className="fa fa-check result_success_icons"></i>
              );
          }else{
              return (
                <i className="fa fa-times result_failed_icons"></i>
              );
          }
          }
      },
      {
        text: "Performance Limitation",
        dataField: "per_limit",
          formatter: (cellContent, row) => {
              if (row.unlock) {
              return (
                <i className="fa fa-check result_success_icons"></i>
              );
          }else{
              return (
                <i className="fa fa-times result_failed_icons"></i>
              );
          }
          }
      },
      {
        text: "UTENTE",
        dataField: "utente"
      },
      {
        text: "Tester",
        dataField: "tester",
          formatter: (cellContent, row) => {
              if (row.unlock) {
              return (
                <i className="fa fa-check result_success_icons"></i>
              );
          }else{
              return (
                <i className="fa fa-times result_failed_icons"></i>
              );
          }
          }
      },
      {
          text: t('versionpage.ling'),
          dataField: "language"
      },
      {
        text: t('versionpage.soc'),
        dataField: "company"
      },
      {
        text: "Windows 10",
        dataField: "windows_10",
          formatter: (cellContent, row) => {
              if (row.windows_10) {
              return (
                <i className="fa fa-check result_success_icons"></i>
              );
          }else{
              return (
                <i className="fa fa-times result_failed_icons"></i>
              );
          }
          }
      },
      {
        text: "Errore",
        dataField: "error"
      },
      {
        text: "Read VIN",
        dataField: "read_vin",
          formatter: (cellContent, row) => {
              if (row.read_vin) {
              return (
                <i className="fa fa-check result_success_icons"></i>
              );
          }else{
              return (
                <i className="fa fa-times result_failed_icons"></i>
              );
          }
          }
      },

];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('versiondetailspage.firstPageText'),
      prePageText: t('versiondetailspage.prePageText'),
      nextPageText: t('versiondetailspage.nextPageText'),
      lastPageText: t('versiondetailspage.lastPageText'),
      nextPageTitle: t('versiondetailspage.nextPageTitle'),
      prePageTitle: t('versiondetailspage.prePageTitle'),
      firstPageTitle: t('versiondetailspage.firstPageTitle'),
      lastPageTitle: t('versiondetailspage.lastPageTitle'),
      showTotal: true,
      totalSize: this.state.products.length
    };
    
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="container-fluid">
          <ToolkitProvider
            keyField="id"
            data={ this.state.products }
            columns={ columns }
            exportCSV={ {  exportAll: false , onlyExportSelection: true, fileName: 'AC Version Data.csv'} }
            >
            {
                props => (
                <div>
                    {this.state.export_btn === true ?
                    <ul className="export_btns">
                        <li><ExportCSVButton { ...props.csvProps }>{t('versiondetailspage.exp_sel')}</ExportCSVButton></li>
                        <li><CSVLink className="react-bs-table-csv-btn btn btn-default" filename={"AC Version Data.csv"} data={this.state.products}>{t('versiondetailspage.exp_all')}</CSVLink></li>
                    </ul> : ""
                    }
                        <br/>
                        <br/>
                        <hr/>
                        <div class="wrapper1">
                          <div class="div1">
                          </div>
                      </div>
                    <BootstrapTable
                    striped
                    hover
                    { ...props.baseProps }
                    selectRow={ selectRow }
                    { ...paginationTableProps }
                    pagination={ paginationFactory(options) }
                    wrapperClasses="table-responsive version_det_table"
                    />
                </div>
                )
            }
            </ToolkitProvider>
      </div>
    );
    const value = queryString.parse(this.props.location.search);
    const sn = value.serial_no;
    return (
      
      <Card>
        <CardBody>
        <MetaTags>
      <title>Security Gateway Details | Nexion Engineering Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#016362" color={'#ffffff'} />
          <br/>
          <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        
                        <div className="modal-body">
                          <Card>
                            <CardBody>
                              <Row>
                                <Col md={6}>
                                <p><b>IP Address:</b> {this.state.locations.ip_address}</p>
                                </Col>
                                <Col md={6}>
                                <p><b>Continent Code:</b> {this.state.locations.continent_code}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                <p><b>Continent Name:</b> {this.state.locations.continent_name}</p>
                                </Col>
                                <Col md={6}>
                                <p><b>Country Code:</b> {this.state.locations.country_code}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                <p><b>Country Name:</b> {this.state.locations.country_name}</p>
                                </Col>
                                <Col md={6}>
                                <p><b>State Prov:</b> {this.state.locations.state_prov}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                <p><b>City:</b> {this.state.locations.city}</p>
                                </Col>
                                <Col md={6}>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                        <div className="modal-footer">
                        
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          
                        </div>
                      </Modal>

                      <Modal
                        isOpen={this.state.modal_standard_cus}
                        toggle={this.tog_standard_cus}
                      >
                        
                        <div className="modal-body">
                          <Card>
                            <CardBody>
                              <Row>
                                <Col md={6}>
                                <p><b>{t('ac_version_details.customer_name')}:</b> {this.state.customer.name}</p>
                                </Col>
                                <Col md={6}>
                                <p><b>{t('ac_version_details.phone')}:</b> {this.state.customer.telephone}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                <p><b>{t('ac_version_details.address')}:</b> {this.state.customer.address}</p>
                                </Col>
                                
                              </Row>
                              
                            </CardBody>
                          </Card>
                        </div>
                        <div className="modal-footer">
                        
                          <button
                            type="button"
                            onClick={this.tog_standard_cus}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          
                        </div>
                      </Modal>
         <br/>
    <h4 className="text-center">Details of Security Gateway - <span className="ver_head">{sn}</span></h4>
        <PaginationProvider
          pagination={
            paginationFactory(options)
          }
        >
          { contentTable }
        </PaginationProvider>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(SecurityGatewayDetails);