import React, { Component } from "react";
import { VectorMap } from "react-jvectormap";
// import "../../css/jquery-jvectormap.css";

//const { getName } = require("country-list")
export class Vectormap extends Component {
  
  // handleClick = (e, countryCode) => {
  //    alert('You Have Selected '+ countryCode);
 
  // };

  render() {
    return (
      <div style={{ width: this.props.width, height: 380 }}>
        <VectorMap
          map={"continents_mill"}
          // onRegionClick={this.handleClick} // gets the country code
          backgroundColor="transparent"
          ref="map"
          containerStyle={{
            width: "100%",
            height: "80%"
          }}
          regionStyle={{
            initial: {
              fill: this.props.color,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 0
            },
            hover: {
              "fill-opacity": 0.8,
              cursor: "pointer"
            },
            selected: {
              fill: "#2938bc" //what colour clicked country will be
            },
            selectedHover: {}
          }}
          containerClassName="map"
        />
      </div>
    );
  }
}

export default Vectormap;
