import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
//import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MetaTags } from "react-meta-tags";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import Loader from "../../components/Loader";
// action
import { addSoftwareVersion } from "../../store/actions";
import { Sugar } from "react-preloaders";
import config from "../../helpers/Constants";

// Redux
import { connect } from "react-redux";

class AddSoftwareVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      states: [],
      cities: [],
      user: [],
      role: "",
      loading: false,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    i18next.changeLanguage(localStorage.getItem("lang"));

    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
  }

  //DatePicker
  handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  handleStart(date) {
    this.setState({ start_date: date });
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(authUser.role);
    this.setState({
      role: authUser.role,
    });
    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.users === 0) {
          window.history.back();
        }
      });
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_name = authUser.username;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: user_name,
      },
    };
    const { frontend, backend, database, remarks } = values;
    console.log("publish_date");
    const date = new Date(this.state.start_date);

    const data = {
      frontend,
      backend,
      db: database,
      publishDate: date.toISOString().split("T")[0],
      remarks,
    };
    // return new Promise((resolve, reject) => {
    axios
      .post(config.API + "software_version?company=Nexion", data, options)
      .then((softwareVersionDetails) => {
        if (softwareVersionDetails.status === 200) {
          alert("Software Version Added Successfully");
          this.props.history.push("/software_version");
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  render() {
    console.log("this.props");
    console.log(this.props);
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Add Software Version | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">
                  {t("software_version_page.add_software_version")}
                </h4>
                {/* {this.props.loading ? <Loader /> : null} */}
              </div>
            </Col>
          </Row>
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <AvField
                      name="frontend"
                      label={t("add_software_version.frontend")}
                      placeholder={t(
                        "add_software_version.frontend_version_placeholder"
                      )}
                      type="text"
                      errorMessage={t("add_software_version.frontend_err")}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="backend"
                      label={t("add_software_version.backend")}
                      placeholder={t(
                        "add_software_version.backend_version_placeholder"
                      )}
                      type="text"
                      errorMessage={t("add_software_version.backend_err")}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="database"
                      label={t("add_software_version.database")}
                      placeholder={t(
                        "add_software_version.database_version_placeholder"
                      )}
                      type="text"
                      errorMessage={t("add_software_version.database_err")}
                      validate={{ required: { value: true } }}
                    />
                  </Col>

                  <Col lg={6}>
                    <div>
                      <label>{t("add_software_version.publish_date")}</label>
                    </div>
                    <DatePicker
                      label={t("add_software_version.publish_date")}
                      selected={this.state.start_date}
                      selectsStart
                      id="date_pick"
                      className="date_pick form-control"
                      placeholderText={t(
                        "add_software_version.publish_date_placeholder"
                      )}
                      name="publish_date"
                      value={this.state.start_date}
                      startDate={this.state.start_date}
                      onChange={this.handleStart}
                      errorMessage={t("add_software_version.publish_date_err")}
                    />
                    <div style={{ padding: "7px" }}></div>
                    <AvField
                      name="remarks"
                      label={t("add_software_version.remarks")}
                      placeholder={t(
                        "add_software_version.remarks_placeholder"
                      )}
                      type="textarea"
                      errorMessage={t("add_software_version.remarks_err")}
                      validate={{ required: { value: false } }}
                    />
                  </Col>
                </Row>
                <FormGroup className="mb-0">
                  <div>
                    <Button type="submit" color="mm" className="mr-1">
                      {t("add_software_version.save")}
                    </Button>{" "}
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </AvForm>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { softwareVersionData, error, loading } = state.SoftwareVersion;
  return { softwareVersionData, error, loading };
};

export default withTranslation()(
  connect(mapStatetoProps, { addSoftwareVersion })(AddSoftwareVersion)
);
//export default AddSoftwareVersion;
