import React from "react";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
//import ToolkitProvider, { CSVExport, ColumnToggle  } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import queryString from "query-string";
import { MetaTags } from "react-meta-tags";
import moment from "moment";
import { Sugar } from "react-preloaders";
import config from "../../helpers/Constants";
import axios from "axios";
import {
  Card,
  CardBody,
  Modal,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
//import { Link } from "react-router-dom";
import ReactExport from "react-data-export-fix1";

const { ExportCSVButton } = CSVExport;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

class AcVersionDetails extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      modal_standard: false,
      modal_standard_cus: false,
      loading: true,
      products: [],
      locations: [],
      customer: [],
      logged_user: "",
      export_btn: false,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_standard_cus = this.tog_standard_cus.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.getVersionLocation = this.getVersionLocation.bind(this);
    this.getVersionCustomer = this.getVersionCustomer.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  tog_standard(loc) {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  tog_standard_cus(loc) {
    this.setState((prevState) => ({
      modal_standard_cus: !prevState.modal_standard_cus,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  componentDidMount() {
    localStorage.removeItem("ACSelectedArray");
    var ACSelectedArray = [];
    localStorage.setItem("ACSelectedArray", JSON.stringify(ACSelectedArray));

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    });

    //console.log(user_id);
    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org" ||
      authUser.role === "Commercial"
    ) {
      this.setState({
        export_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.ac_version === 0) {
          window.history.back();
        }
      });

    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const sr_n = values.serial_no;
    //const sn_no = params.get('serial_no');
    //const version_id = this.props.location.query.id;
    fetch(config.API + "ac_versions/" + sr_n, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        this.setState({
          products: findresponse,
          loading: false,
        });
      });
  }

  getVersionLocation(id) {
    var loc_id = id.target.value;
    fetch(config.API + "ac_version_location/" + loc_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse)
        this.setState({
          locations: findresponse,
        });
      });
    this.tog_standard();
    //alert(loc_id);
  }

  getVersionCustomer(id) {
    var ver_id = id.target.value;
    fetch(config.API + "ac_version_customer/" + ver_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse)
        this.setState({
          customer: findresponse,
        });
      });
    this.tog_standard_cus();
    //alert(loc_id);
  }

  handleExportSubmit(event, values) {
    var exp_values = {
      type: "Export",
      note: "AC Versions Details Export",
      company: "Nexion",
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  handleOnSelect = (row, isSelect) => {
    var selData = JSON.parse(localStorage.getItem("ACSelectedArray") || "[]");
    function remove(key) {
      var i = selData.findIndex((seldat) => seldat.key === key);
      if (i !== -1) {
        selData.splice(i, 1);
        localStorage.setItem("ACSelectedArray", JSON.stringify(selData));
      }
    }
    if (isSelect === true) {
      var nkey = row.key;
      var newSelectedArray = JSON.parse(
        localStorage.getItem("ACSelectedArray")
      );
      newSelectedArray.push(row);
      localStorage.setItem("ACSelectedArray", JSON.stringify(newSelectedArray));
    } else {
      remove(row.key);
    }
  };

  render() {
    const { t } = this.props;
    const columns = [
      {
        text: t("versiondetailspage.int_date"),
        dataField: "update_date",
        formatter: (rowContent, row) => {
          var up_date = row.update_date.replace(/T/, " ").replace(/\..+/, "");
          return up_date;
        },
      },
      {
        text: t("versiondetailspage.strum"),
        dataField: "device_name",
      },
      {
        text: t("versiondetailspage.soc"),
        dataField: "company",
      },
      {
        text: t("versiondetailspage.fam"),
        isDummyField: true,
        dataField: "continent_code",
        formatter: (cellContent, row) => {
          if (row.continent_code !== "" && row.continent_code !== null) {
            return (
              <button
                onClick={this.tog_standard && this.getVersionLocation}
                data-toggle="modal"
                value={row.id}
                data-target="#myModal"
                className="btn btn-outline-nexion loc_btn lg"
              >
                {t("versiondetailspage.loc")}
              </button>
            );
          } else {
            return <span>N/A</span>;
          }
        },
      },
      {
        text: t("ac_version_details.customer"),
        isDummyField: true,
        dataField: "cus_name",
        formatter: (cellContent, row) => {
          if (row.cus_name !== "" && row.cus_name !== null) {
            return (
              <button
                onClick={this.tog_standard_cus && this.getVersionCustomer}
                data-toggle="modal"
                value={row.id}
                data-target="#myModalCus"
                className="btn btn-outline-nexion loc_btn lg"
              >
                {t("ac_version_details.view_customer")}
              </button>
            );
          } else {
            return <span>N/A</span>;
          }
        },
      },
      {
        text: t("versiondetailspage.ling"),
        dataField: "language",
      },
      {
        text: t("versiondetailspage.cks"),
        isDummyField: true,
        dataField: "update_result",
        formatter: (cellContent, row) => {
          if (row.update_result === 1) {
            return (
              <h5>
                <span className="label label-success">OK</span>
              </h5>
            );
          } else {
            return (
              <h5>
                <span className="label label-danger">NOK</span>
              </h5>
            );
          }
        },
      },
      {
        text: t("versiondetailspage.exp"),
        dataField: "expiring_date",
        formatter: (rowContent, row) => {
          var o_exp_date = moment(row.expiring_date).format("YYYY-MM-DD");
          //const id = row.id
          if (row.expiring_date === "0000-00-00") {
            var exp_date = "N/A";
          } else {
            var exp_date = o_exp_date;
          }
          return exp_date;
        },
      },
      {
        text: t("versiondetailspage.password"),
        dataField: "password",
      },
      {
        text: "BOOTLOADER",
        dataField: "bootloader",
      },
      {
        text: "HMI",
        dataField: "hmi",
      },
      {
        text: "PLC",
        dataField: "plc",
      },
      {
        text: "Database",
        dataField: "data_base",
      },
      {
        text: "Model",
        dataField: "model",
      },
      {
        text: "Gas Type",
        dataField: "gas_type",
      },
      {
        text: t("versiondetailspage.ver_os"),
        dataField: "os_version",
        formatter: (cellContent, row) => {
          if (row.os_version === "android") {
            return <i className="ion ion-logo-android android_logo"></i>;
          } else if (row.os_version === "windows") {
            return <i className="ion ion-logo-windows windows_logo"></i>;
          } else if (row.os_version === "embedded") {
            return <span>Embedded</span>;
          } else {
            return <span>Undefined</span>;
          }
        },
      },
    ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t("versiondetailspage.firstPageText"),
      prePageText: t("versiondetailspage.prePageText"),
      nextPageText: t("versiondetailspage.nextPageText"),
      lastPageText: t("versiondetailspage.lastPageText"),
      nextPageTitle: t("versiondetailspage.nextPageTitle"),
      prePageTitle: t("versiondetailspage.prePageTitle"),
      firstPageTitle: t("versiondetailspage.firstPageTitle"),
      lastPageTitle: t("versiondetailspage.lastPageTitle"),
      showTotal: true,
      totalSize: this.state.products.length,
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectAll: true,
      onSelect: this.handleOnSelect,
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "AC Version Data.csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                <li>
                  <button
                    type="button"
                    onClick={this.tog_standard}
                    className="btn filter_btn waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i className="ti-filter"></i>
                  </button>
                </li>
                {this.state.export_btn === true ? (
                  <li>
                    <Dropdown
                      isOpen={this.state.btnprimary1}
                      direction="left"
                      toggle={() =>
                        this.setState({
                          btnprimary1: !this.state.btnprimary1,
                        })
                      }
                    >
                      <DropdownToggle tag="button" className="btn btn-success">
                        {t("versionpage.export")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_sel")}
                              </span>
                            }
                            filename="AC Versions Details"
                          >
                            <ExcelSheet
                              data={JSON.parse(
                                localStorage.getItem("ACSelectedArray")
                              )}
                              name="AC Versions"
                            >
                              <ExcelColumn label="ID" value="id" />
                              <ExcelColumn
                                label="Serial Number"
                                value="serial_number"
                              />
                              <ExcelColumn
                                label="Device Name"
                                value="device_name"
                              />
                              <ExcelColumn label="Language" value="language" />
                              <ExcelColumn label="Company" value="company" />
                              <ExcelColumn label="Password" value="password" />
                              <ExcelColumn
                                label="Expiring Date"
                                value="expiring_date"
                              />
                              <ExcelColumn
                                label="Update Date"
                                value="update_date"
                              />
                              <ExcelColumn
                                label="Update Result"
                                value="update_result"
                              />
                              <ExcelColumn
                                label="OS Version"
                                value="os_version"
                              />
                              <ExcelColumn
                                label="Date Added"
                                value="date_added"
                              />
                              <ExcelColumn
                                label="bootloader"
                                value="bootloader"
                              />
                              <ExcelColumn label="hmi" value="hmi" />
                              <ExcelColumn label="plc" value="plc" />
                              <ExcelColumn
                                label="data_base"
                                value="data_base"
                              />
                              <ExcelColumn label="model" value="model" />
                              <ExcelColumn label="gas_type" value="gas_type" />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                        <DropdownItem>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_all")}
                              </span>
                            }
                            filename="AC Verions Details"
                          >
                            <ExcelSheet
                              data={this.state.products}
                              name="AC Versions"
                            >
                              <ExcelColumn label="ID" value="id" />
                              <ExcelColumn
                                label="Serial Number"
                                value="serial_number"
                              />
                              <ExcelColumn
                                label="Device Name"
                                value="device_name"
                              />
                              <ExcelColumn label="Language" value="language" />
                              <ExcelColumn label="Company" value="company" />
                              <ExcelColumn label="Password" value="password" />
                              <ExcelColumn
                                label="Expiring Date"
                                value="expiring_date"
                              />
                              <ExcelColumn
                                label="Update Date"
                                value="update_date"
                              />
                              <ExcelColumn
                                label="Update Result"
                                value="update_result"
                              />
                              <ExcelColumn
                                label="OS Version"
                                value="os_version"
                              />
                              <ExcelColumn
                                label="Date Added"
                                value="date_added"
                              />
                              <ExcelColumn
                                label="bootloader"
                                value="bootloader"
                              />
                              <ExcelColumn label="hmi" value="hmi" />
                              <ExcelColumn label="plc" value="plc" />
                              <ExcelColumn
                                label="data_base"
                                value="data_base"
                              />
                              <ExcelColumn label="model" value="model" />
                              <ExcelColumn label="gas_type" value="gas_type" />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <br />
              <br />
              <hr />
              <div class="wrapper1">
                <div class="div1"></div>
              </div>
              <BootstrapTable
                striped
                hover
                {...props.baseProps}
                selectRow={selectRow}
                {...paginationTableProps}
                pagination={paginationFactory(options)}
                wrapperClasses="table-responsive version_det_table"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    const value = queryString.parse(this.props.location.search);
    const sn = value.serial_no;
    return (
      <Card>
        <CardBody>
          <MetaTags>
            <title>AC Version Details | Nexion Engineering Web Portal</title>
            <meta
              name="description"
              content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
            />
          </MetaTags>
          <Sugar
            customLoading={this.state.loading}
            background="#016362"
            color={"#ffffff"}
          />
          <br />
          <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
            <div className="modal-body">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>IP Address:</b> {this.state.locations.ip_address}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>Continent Code:</b>{" "}
                        {this.state.locations.continent_code}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>Continent Name:</b>{" "}
                        {this.state.locations.continent_name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>Country Code:</b> {this.state.locations.country_code}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>Country Name:</b> {this.state.locations.country_name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>State Prov:</b> {this.state.locations.state_prov}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>City:</b> {this.state.locations.city}
                      </p>
                    </Col>
                    <Col md={6}></Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.tog_standard}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.modal_standard_cus}
            toggle={this.tog_standard_cus}
          >
            <div className="modal-body">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <p>
                        <b>{t("ac_version_details.customer_name")}:</b>{" "}
                        {this.state.customer.name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p>
                        <b>{t("ac_version_details.phone")}:</b>{" "}
                        {this.state.customer.telephone}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p>
                        <b>{t("ac_version_details.address")}:</b>{" "}
                        {this.state.customer.address}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={this.tog_standard_cus}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
          <br />
          <h4 className="text-center">
            {t("versiondetailspage.version_rec")} -{" "}
            <span className="ver_head">{sn}</span>
          </h4>
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(AcVersionDetails);
