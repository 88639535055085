import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import {MetaTags} from 'react-meta-tags';
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import config from './../../helpers/Constants';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Loader from "../../components/Loader";

// import images
import logosmImg from "../../assets/images/nexion.png"; 

class UpdatePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        update_email:'',
        u_token:''
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {

    const token = this.props.match.params.token;
    //diag active users
    fetch(config.API+'forget_password_token_exp/'+token+'?company=Nexion', {
        headers: {  
          'Nexion_API_Key': config.API_KEY
        }
      }).then((Response)=>Response.json()).then((findresponse)=>{
          if(findresponse.status === "failed"){
            alert("Password reset link has been expired!!");
            window.location.href = "http://nexiondevportal.com/login"; 
          }else{
            this.setState({
                update_email:findresponse.email_id,
                u_token:token
              })
          }
      })
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    
    const options = {
      headers: {
        'Nexion_API_Key': config.API_KEY,
        'email_id': this.state.update_email,
        'u_token': this.state.u_token
      }
    };
    return new Promise((resolve, reject) => {
    
      axios.post(config.API+'update_password?company=Nexion', values, options)
        .then(
          user => {
            //console.log(user);
            if (user.data.status === "success") {
              alert("Password Changed Successfully!!");
              window.location.href = "http://nexiondevportal.com/login"; 
            }else if (user.data.status === "failed") {
              alert("Unable to change password, Please try again!!");
            }
            else{
              alert("There is some technical problem!!");
            }
          },
          error => {
            reject(this._handleError(error));
          })
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="forget-component">
        <MetaTags>
            <title>Change Password | Nexion Engineering Web Portal</title>
            <meta name="description" content="Diagnostic Portal For Nexion Engineering" />
          </MetaTags>
        <div className="home-btn d-none d-sm-block forget-component">
        
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-nexion">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          Change Password
                        </h5>
                        <p className="text-white-50">
                          Enter your new password.
                        </p>
                        <Link to="/" className="logo logo-admin">
                          <img src={logosmImg} height="25" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        {this.props.forgetError && this.props.forgetError ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {this.props.forgetError}
                          </Alert>
                        ) : null}
                        {this.props.forgetSuccess ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {this.props.forgetSuccess}
                          </Alert>
                        ) : null}

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="NEW PASSWORD"
                              type="password"
                              required
                              placeholder="Password"
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="cnf_password"
                              label="CONFIRM NEW PASSWORD"
                              type="password"
                              validate={{match:{value:'password'}}}
                              errorMessage="New password and confirm password don't match"
                              required
                              placeholder="Confirm Password"
                            />
                          </div>
                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-nexion w-md waves-effect waves-light"
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p className="text-white">
                      Go back to{" "}
                      <Link
                        to="login"
                        className="font-weight-medium text-warning"
                      >
                        Login
                      </Link>{" "}
                    </p>
                    <p className="mb-0 text-white">
                    © {new Date().getFullYear()} Nexion SPA. <b>- Powered by Nexion Engineering.</b>
                  </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect()(UpdatePasswordPage)
);
